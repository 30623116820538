body {
    @if $type=="" {}

    @if $type==sp {
        padding-top: 100px;
    }

    @if $type==pc {
        padding-top: 126px;
    }
}

#header {
    @if $type=="" {}

    @if $type==sp {
        width: calc(100% - 20px);
        height: 60px;
        top: 10px;
        left: 10px;
        border-radius: 30px;
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

        #menuBtn {
            top: 8px;
            right: 10px;
        }

        #logo {
            height: 100%;
            background-color: transparent;
        }
    }

    @if $type==pc {
        width: calc(100% - 60px);
        height: 74px;
        border-radius: 52px;
        background-color: #fff;
        left: 30px;
        top: 13px;

        #logo {
            background-color: transparent;
            height: 100%;

            img {
                max-width: 200px;
            }
        }

        #globalMenu ul a:before {
            top: 15px;
        }

        #globalMenu ul a:after {
            bottom: 0;
        }

    }
}


.breadcrumb {
    @if $type=="" {
        display: flex;

        a {
            color: $c_base;
            text-decoration: underline;
        }
    }

    @if $type==sp {
        padding-left: 20px;
    }

    @if $type==pc {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding-left: 60px;

        a:hover {
            text-decoration: none;
        }
    }

    >span {
        @if $type=="" {
            display: inline-block;
            position: relative;
            color: #87878a;
            white-space: nowrap;

            &:not(:last-of-type)::after {
                content: '';
                width: 4px;
                height: 4px;
                border: 0px;
                border-top: solid 1px $c_base;
                border-right: solid 1px $c_base;
                transform: rotate(45deg);
                position: absolute;
                top: calc(50% - 2px);
                right: -16px;
            }

            &:last-of-type {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        @if $type==sp {
            font-size: 13px;

            +span {
                margin-left: 24px;
            }

            &:not(:last-of-type)::after {
                right: -13px;
            }
        }

        @if $type==pc {
            font-size: 13px;

            +span {
                margin-left: 30px;
            }
        }
    }
}


.wp-pagenavi {
    @if $type =="" {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        line-height: 1;
    }

    @if $type ==sp {
        margin-top: 80px;
        font-size: 14px;
    }

    @if $type ==pc {
        margin-top: 80px;
        font-size: 15px;
    }

    .page,
    .extend,
    .last,
    .first {
        @if $type =="" {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            background-color: #fff;
            border-radius: 50%;
            color: #2C3A51;
        }

        @if $type ==sp {
            width: 40px;
            height: 40px;
            margin: 0 10px;
        }

        @if $type ==pc {
            width: 43px;
            height: 43px;
            margin: 0 10px;
            transition: .5s;

            &:hover {
                background-color: #EBEBEB;
                color: $c_base;
            }
        }
    }

    span.current {
        @if $type =="" {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            background-color: $c_base;
            color: #fff;
            border-radius: 50%;
        }

        @if $type ==sp {
            width: 40px;
            height: 40px;
            margin: 0 10px;
        }

        @if $type ==pc {
            width: 43px;
            height: 43px;
            margin: 0 10px;
        }
    }
}

//wp-pagenavi

.pageTitleWrap {
    @if $type=="" {}

    @if $type==sp {
        margin: 30px auto 0;
        padding-left: 20px;
    }

    @if $type==pc {
        width: calc(100% - 60px);
        margin: 35px auto 0;
        padding-left: 60px;
    }


    .pageTitle {
        @if $type=="" {
            font-weight: 400;
            font-family: "Unbounded", "Noto Sans JP", sans-serif;

            &.jp {
                @include fontJa;
            }
        }

        @if $type==sp {
            font-size: 36px;
        }

        @if $type==pc {
            font-size: 35px;
        }
    }
}

.commonInner {
    @if $type==sp {
        padding: 0 20px;
    }

    @if $type==pc {
        @include maxPC;
    }
}

main {
    @if $type=="" {
        a {
            color: #2663B5;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

#contents {
    @if $type==sp {
        padding: 37px 0 120px;
    }

    @if $type==pc {
        padding: 40px 0 130px;
    }
}

.catList {
    @if $type=="" {
        background-color: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    @if $type==sp {
        display: flex;
        border-radius: 10px;
        gap: 6px;
        padding: 5px;
    }

    @if $type==pc {
        display: flex;
        border-radius: 30px;
        gap: 20px;
        padding: 5px 6px;
        max-width: 874px;
        margin: 0 auto;
    }

    .catItem {
        @if $type=="" {
            width: calc(100%/4);
        }

        @if $type==sp {}

        @if $type==pc {}

        a {
            @if $type=="" {
                width: 100%;
                background-color: #F9F9FB;
                border: 1px solid #F2F2F2;
                color: #2C3A51;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                text-decoration: none;

                &.active {
                    color: #fff;
                    background-color: #2C3A51;
                }
            }

            @if $type==sp {
                height: 32px;
                font-size: 11px;
                cursor: pointer;
                border-radius: 8px;
            }

            @if $type==pc {
                height: 48px;
                font-size: 14px;
                cursor: pointer;
                border-radius: 24px;

                &:hover {
                    color: #fff;
                    background-color: #2C3A51;
                }
            }
        }
    }
}

.newsList {
    @if $type==sp {
        margin-top: 24px;
    }

    @if $type==pc {
        margin-top: 57px;
    }
}

.contBtmLink {
    @if $type=="" {}

    @if $type==sp {
        padding: 0 20px 20px;
    }

    @if $type==pc {
        display: flex;
        gap: 2px;
    }

    a {
        @if $type=="" {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            background: rgb(44, 58, 81);
            background: linear-gradient(180deg, rgba(44, 58, 81, 1) 0%, rgba(49, 49, 49, 1) 100%);
        }

        @if $type==sp {
            height: 140px;

            +a {
                margin-top: 10px;
            }
        }

        @if $type==pc {
            width: calc(50% - 1px);
            height: 340px;
            transition: .5s;

            &:hover {
                filter: brightness(1.4);
            }
        }
    }

    .enTitle {
        @if $type=="" {
            position: relative;
            font-weight: 400;
            @include fontEn;
            color: #fff;
            line-height: 1;
        }

        @if $type==sp {
            font-size: 28px;
            padding-left: 30px;

            &:before {
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 18px;
                height: 18px;
                position: absolute;
                left: 0;
                top: calc(50% - 9px);
            }
        }

        @if $type==pc {
            margin: 0 auto;
            font-size: 35px;
            padding-left: 40px;

            &:before {
                content: "";
                display: inline-block;
                background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
                width: 23px;
                height: 23px;
                position: absolute;
                left: 0;
                top: calc(50% - 12px);
            }
        }
    }

    .jpTitle {
        @if $type=="" {
            margin: 0;
        }

        @if $type==sp {
            font-size: 14px;
            margin-top: 15px;
        }

        @if $type==pc {
            font-size: 20px;
            margin-top: 12px;
        }
    }
}

.postH1 {
    @if $type=="" {
        font-weight: bold;
        line-height: 1.5;
    }

    @if $type==sp {
        font-size: 20px;
    }

    @if $type==pc {
        font-size: 18px;
    }
}

.ymdcat {
    @if $type=="" {
        display: flex;
    }

    @if $type==sp {
        margin-top: 20px;
        gap: 15px;
        align-items: center;
    }

    @if $type==pc {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;
    }

    .ymd {
        @if $type=="" {
            font-weight: bold;
            color: #B2B2B2;
        }

        @if $type==sp {
            font-size: 12px;
        }

        @if $type==pc {
            font-size: 13px;
        }
    }

    .cat {
        @if $type=="" {
            background-color: #fff;
            display: inline-block;
            border-radius: 24px;
        }

        @if $type==sp {
            min-width: 84px;
            text-align: center;
            padding: 4px 15px;
            font-size: 10px;
        }

        @if $type==pc {
            min-width: 97px;
            text-align: center;
            padding: 6px 15px;
            font-size: 11px;
            margin-top: 15px;
        }
    }
}

.singleContents {
    @if $type=="" {
        line-height: 2;
    }

    @if $type==sp {}

    @if $type==pc {
        font-size: 14px;
    }
}

.companyMapList {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
    }

    .companyMap {
        @if $type=="" {}

        @if $type==sp {
            +.companyMap {
                margin-top: 30px;
            }
        }

        @if $type==pc {
            width: calc(50% - 25px);
        }

        dl {
            @if $type==pc {
                display: flex;
                align-items: flex-start;
            }

            dt {
                @if $type=="" {
                    color: #fff;
                    display: inline-block;
                    background-color: #2C3A51;
                    min-width: 75px;
                    border-radius: 17px;
                    padding: 4px 10px;
                    text-align: center;
                }

                @if $type==sp {}

                @if $type==pc {
                    flex: none;
                }
            }

            dd {
                @if $type==sp {
                    margin-top: 15px;
                }

                @if $type==pc {
                    margin-left: 13px;
                }
            }
        }

        .map {
            @if $type==sp {
                margin-top: 15px;

                iframe {
                    width: 100%;
                    height: 184px;
                }
            }

            @if $type==pc {
                margin-top: 15px;

                iframe {
                    width: 100%;
                    height: 250px;
                }
            }
        }
    }
}

.memberList {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 39px;
    }

    .member {
        @if $type=="" {}

        @if $type==sp {
            +.member {
                margin-top: 30px;
            }
        }

        @if $type==pc {
            width: calc((100% / 3) - 26px);
        }

        .imgname {
            @if $type=="" {}

            @if $type==sp {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-left: 5px;
            }

            @if $type==pc {}

            figure {
                @if $type=="" {
                    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

                    img {
                        width: 100%;
                    }
                }

                @if $type==sp {
                    width: calc(50% - 5px);
                    border-radius: 14px;
                    border: 3px solid #fff;
                    overflow: hidden;
                }

                @if $type==pc {
                    border-radius: 24px;
                    border: 5px solid #fff;
                    overflow: hidden;
                }
            }

            dl {
                @if $type=="" {}

                @if $type==sp {
                    width: calc(50% - 5px);
                }

                @if $type==pc {
                    margin-top: 21px;
                }

                dt {
                    @if $type=="" {
                        font-weight: bold;
                        color: $c_base;
                    }

                    @if $type==sp {
                        font-size: 15px;
                    }

                    @if $type==pc {}
                }

                dd {
                    @if $type=="" {
                        font-weight: bold;
                    }

                    @if $type==sp {
                        font-size: 24px;
                        margin-top: 8px;
                    }

                    @if $type==pc {
                        font-size: 26px;
                        margin-top: 8px;
                    }
                }
            }
        }

        p {
            @if $type=="" {}

            @if $type==sp {}

            @if $type==pc {
                font-size: 13px;
                margin-top: 25px;
            }
        }
    }
}

.aboutH2 {
    @if $type=="" {
        font-weight: bold;
        font-feature-settings: "palt";
    }

    @if $type==sp {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    @if $type==pc {
        font-size: 27px;
        margin-top: 10px;
        margin-bottom: 50px;
    }
}

.seizoukouteiWrap {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        max-width: 868px;
        margin: 65px auto 0;
    }

    h3 {
        @if $type=="" {
            background-color: #2C3A51;
            color: #fff;
            text-align: center;
            font-weight: bold;
        }

        @if $type==sp {
            font-size: 22px;
            padding: 8px 30px;
            border-radius: 50px;
        }

        @if $type==pc {
            font-size: 24px;
            padding: 8px 30px;
            border-radius: 28px;
        }
    }

    figure {
        @if $type=="" {
            text-align: center;
        }

        @if $type==sp {
            margin-top: 30px;
        }

        @if $type==pc {
            margin-top: 50px;
        }
    }
}

.serviceLinkList {
    @if $type=="" {}

    @if $type==sp {
        padding: 0 20px;
    }

    @if $type==pc {
        max-width: 1223px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        gap: 12px;
    }

    li {
        @if $type=="" {
            background-color: #fff;
        }

        @if $type==sp {
            +li {
                margin-top: 10px;
            }
        }

        @if $type==pc {
            text-align: center;
            width: calc(25% - 9px);
        }

        a {
            @if $type=="" {
                display: block;
                color: $c_text;
                text-decoration: none;
                position: relative;
                width: 100%;
                height: 100%;
            }

            @if $type==sp {
                padding: 15px 12px 38px;
                display: flex;
                align-items: center;
                gap: 12px;

                &:after {
                    content: "";
                    display: inline-block;
                    width: 34px;
                    height: 17px;
                    border-radius: 22px;
                    background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                    background-size: 18px auto;
                    background-color: #2C3A51;
                    border: 1px solid #2C3A51;
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    transition: .5s;
                }
            }

            @if $type==pc {
                padding: 30px 20px 50px;

                &:after {
                    content: "";
                    display: inline-block;
                    width: 47px;
                    height: 24px;
                    border-radius: 22px;
                    background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #2C3A51;
                    border: 1px solid #2C3A51;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    transition: .5s;
                }

                &:hover:after {
                    background: url(../images/common/arrow_l.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #fff;
                }
            }

            figure {
                @if $type=="" {}

                @if $type==sp {
                    width: 100px;
                    flex: none;
                    padding-top: 15px;
                }

                @if $type==pc {
                    img {
                        max-height: 40px;
                    }
                }
            }

            .title {
                @if $type=="" {
                    font-weight: bold;
                }

                @if $type==sp {
                    font-size: 15px;
                }

                @if $type==pc {
                    font-size: 15px;
                    margin-top: 20px;
                }
            }

            .text {
                @if $type=="" {
                    color: #888888;
                }

                @if $type==sp {
                    font-size: 12px;
                    margin-top: 10px;
                }

                @if $type==pc {
                    font-size: 12px;
                    margin-top: 15px;
                }
            }
        }
    }
}

.contactLinkList {
    @if $type=="" {}

    @if $type==sp {
        padding: 0 20px;
    }

    @if $type==pc {
        max-width: 1223px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        gap: 18px;
    }

    li {
        @if $type=="" {
            background-color: #fff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }

        @if $type==sp {
            +li {
                margin-top: 20px;
            }
        }

        @if $type==pc {
            width: calc(50% - 9px);
        }

        a {
            @if $type=="" {
                display: block;
                color: $c_text;
                text-decoration: none;
                position: relative;
                width: 100%;
                height: 100%;
            }

            @if $type==sp {
                padding: 15px 12px;
                display: flex;
                align-items: center;
                gap: 12px;

                &:after {
                    content: "";
                    display: inline-block;
                    width: 34px;
                    height: 17px;
                    border-radius: 22px;
                    background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                    background-size: 18px auto;
                    background-color: #2C3A51;
                    border: 1px solid #2C3A51;
                    position: absolute;
                    bottom: 17px;
                    right: 12px;
                    transition: .5s;
                }
            }

            @if $type==pc {
                padding: 30px 20px;

                &:after {
                    content: "";
                    display: inline-block;
                    width: 47px;
                    height: 24px;
                    border-radius: 22px;
                    background: url(../images/common/arrow_l_w.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #2C3A51;
                    border: 1px solid #2C3A51;
                    position: absolute;
                    bottom: 30px;
                    right: 10px;
                    transition: .5s;
                }

                &:hover:after {
                    background: url(../images/common/arrow_l.svg) no-repeat center / cover;
                    background-size: 20px auto;
                    background-color: #fff;
                }
            }

            figure {
                @if $type=="" {}

                @if $type==sp {
                    width: 100px;
                    flex: none;
                    padding-top: 15px;
                }

                @if $type==pc {
                    img {
                        max-height: 40px;
                    }
                }
            }

            .title {
                @if $type=="" {
                    font-weight: bold;
                }

                @if $type==sp {
                    font-size: 15px;
                }

                @if $type==pc {
                    font-size: 15px;
                    margin-top: 20px;
                }
            }

            .text {
                @if $type=="" {
                    color: #888888;
                }

                @if $type==sp {
                    font-size: 12px;
                    margin-top: 10px;
                }

                @if $type==pc {
                    font-size: 12px;
                    margin-top: 15px;
                }
            }
        }
    }
}

dl.awardsList {

    
    @if $type=="" {
        font-family: "Noto Sans JP", sans-serif !important;
    }
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 25px;
    }

    >div {
        @if $type=="" {
            background-color: #fff;
            border-radius: 5px;
        }

        @if $type==sp {
            padding: 12px;

            +div {
                margin-top: 15px;
            }
        }

        @if $type==pc {
            display: flex;
            padding: 13px 25px;

            +div {
                margin-top: 10px;
            }
        }

        >dt {
            @if $type=="" {
                font-weight: 400;
            }

            @if $type==sp {}

            @if $type==pc {
                width: 50px;
                flex: none;
            }
        }

        >dd {
            @if $type=="" {
                font-weight: 400;
            }

            @if $type==sp {
                margin-top: 5px;
            }

            @if $type==pc {
                padding-left: 38px;

                &:nth-of-type(1) {
                    width: calc(60% - 50px);
                }

                &:nth-of-type(2) {
                    width: 40%;
                }
            }
        }
    }
}

.serviceWrap {
    @if $type=="" {
        position: relative;

        &#culnetconsortium {
            &:before {
                background-color: #69B6E2;
            }

            h3 {
                color: #69B6E2;
            }
        }

        &#ocattebase {
            &:before {
                background-color: #D25E27;
            }

            h3 {
                color: #D25E27;
            }
        }

        &#pipeline {
            &:before {
                background-color: #2D3C52;
            }

            h3 {
                color: #2D3C52;
            }
        }

        &#cellag {
            &:before {
                background-color: #F1EB6D;
            }

            h3 {
                color: #BEB71B;
            }
        }
    }

    @if $type==sp {
        margin-top: 60px;
        padding: 0 20px;

        &:before {
            content: "";
            width: 100%;
            height: 350px;
            position: absolute;
            left: 0;
            top: calc(50% - 175px);
        }
    }

    @if $type==pc {
        margin-top: 125px;

        &:before {
            content: "";
            width: 100%;
            height: 450px;
            position: absolute;
            left: 0;
            top: calc(50% - 225px);
        }
    }

    .serviceDetailBox {
        @if $type=="" {
            border-radius: 18px;
            background-color: #fff;
            position: relative;
        }

        @if $type==sp {
            padding: 40px 20px 50px;

            &.culnetconsortium {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_rensa.svg) no-repeat center / cover;
                    width: 80px;
                    height: 52px;
                    position: absolute;
                    top: -25px;
                    right: -20px;
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_rensa.svg) no-repeat center / cover;
                    width: 80px;
                    height: 52px;
                    transform: scale(1, -1);
                    position: absolute;
                    bottom: -27px;
                    left: -20px;
                }
            }

            &.ocattebase {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_kakushin.svg) no-repeat center / cover;
                    width: 68px;
                    height: 52px;
                    position: absolute;
                    top: -25px;
                    right: -18px;
                    transform: scale(-1, 1);
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_kakushin.svg) no-repeat center / cover;
                    width: 68px;
                    height: 52px;
                    position: absolute;
                    bottom: -27px;
                    left: -18px;
                }
            }

            &.pipeline {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_seichou.svg) no-repeat center / cover;
                    width: 80px;
                    height: 36px;
                    position: absolute;
                    top: -25px;
                    right: -18px;
                    transform: rotate(10deg);
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_seichou.svg) no-repeat center / cover;
                    width: 80px;
                    height: 36px;
                    position: absolute;
                    bottom: -27px;
                    left: -18px;
                    transform: rotate(-10deg);
                }
            }

            &.cellag {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_hirogari.svg) no-repeat center / cover;
                    width: 80px;
                    height: 33px;
                    position: absolute;
                    top: -25px;
                    right: -18px;
                    transform: rotate(10deg);
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_hirogari.svg) no-repeat center / cover;
                    width: 80px;
                    height: 33px;
                    position: absolute;
                    bottom: -27px;
                    left: -18px;
                    transform: rotate(-10deg);
                }
            }
        }

        @if $type==pc {
            padding: 50px;
            max-width: 1000px;
            margin: 0 auto;

            &.culnetconsortium {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_rensa.svg) no-repeat center / cover;
                    width: 174px;
                    height: 113px;
                    position: absolute;
                    top: -58px;
                    right: -60px;
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_rensa.svg) no-repeat center / cover;
                    width: 174px;
                    height: 113px;
                    transform: scale(1, -1);
                    position: absolute;
                    bottom: -74px;
                    left: -80px;
                }
            }

            &.ocattebase {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_kakushin.svg) no-repeat center / cover;
                    width: 136px;
                    height: 104px;
                    position: absolute;
                    top: -68px;
                    right: -52px;
                    transform: scale(-1, 1);
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_kakushin.svg) no-repeat center / cover;
                    width: 136px;
                    height: 104px;
                    position: absolute;
                    bottom: -29px;
                    left: -68px;
                }
            }

            &.pipeline {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_seichou.svg) no-repeat center / cover;
                    width: 111px;
                    height: 50px;
                    position: absolute;
                    top: -10px;
                    right: -32px;
                    transform: rotate(10deg);
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_seichou.svg) no-repeat center / cover;
                    width: 111px;
                    height: 50px;
                    position: absolute;
                    bottom: -10px;
                    left: -32px;

                    transform: rotate(-10deg);
                }
            }

            &.cellag {
                &:before {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_hirogari.svg) no-repeat center / cover;
                    width: 112px;
                    height: 47px;
                    position: absolute;
                    top: -27px;
                    right: -45px;
                    transform: rotate(10deg);
                }

                &:after {
                    content: "";
                    display: inline-block;
                    background: url(../images/common/mark_hirogari.svg) no-repeat center / cover;
                    width: 112px;
                    height: 47px;
                    position: absolute;
                    bottom: -27px;
                    left: -45px;
                    transform: rotate(-10deg);
                }
            }
        }

        >h2 {
            @if $type=="" {
                text-align: center;
            }
        }

        >h3 {
            @if $type=="" {
                text-align: center;
                font-weight: bold;
            }

            @if $type==sp {
                margin-top: 30px;
                font-size: 22px;
            }

            @if $type==pc {
                margin-top: 30px;
                font-size: 22px;
            }
        }

        >p {
            @if $type=="" {}

            @if $type==sp {
                margin-top: 30px;
                font-size: 14px;
            }

            @if $type==pc {
                margin-top: 30px;
                font-size: 14px;
                text-align: center;
            }
        }

        >figure {
            @if $type=="" {
                text-align: center;
            }

            @if $type==sp {
                margin-top: 30px;
            }

            @if $type==pc {
                margin-top: 40px;
            }
        }

        .serviceImgList {
            @if $type=="" {}

            @if $type==sp {
                margin-top: 30px;
            }

            @if $type==pc {
                margin-top: 40px;
                display: flex;
                gap: 30px;
            }

            >div {
                @if $type=="" {
                    background-color: #FFFEED;
                    border-radius: 13px;
                }

                @if $type==sp {
                    padding: 17px 20px 15px;

                    +div {
                        margin-top: 20px;
                    }
                }

                @if $type==pc {
                    padding: 17px 20px 15px;
                }

                h4 {
                    @if $type=="" {
                        color: #BEB71B;
                        text-align: center;
                        font-weight: bold;
                    }

                    @if $type==sp {
                        font-size: 16px;
                    }

                    @if $type==pc {
                        font-size: 18px;
                    }
                }

                p {
                    @if $type=="" {}

                    @if $type==sp {
                        margin-top: 15px;
                    }

                    @if $type==pc {
                        margin-top: 15px;
                    }
                }

                figure {
                    @if $type=="" {}

                    @if $type==sp {
                        margin-top: 15px;
                    }

                    @if $type==pc {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

.serviceTitle {
    @if $type=="" {
        font-weight: 400;
        @include fontEn;
        line-height: 1;
    }

    @if $type==sp {
        margin-top: 90px;
        font-size: 28px;

        +.h2sub {
            font-size: 15px;
            margin-top: 5px;
        }
    }

    @if $type==pc {
        margin-top: 120px;
        font-size: 35px;

        +.h2sub {
            font-size: 20px;
            margin-top: 5px;
        }
    }
}

.businessArea {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 50px;
    }
}

//modal
.modaal-container {
    @if $type=="" {
        border-radius: 9px;

        .modaal-content-container {
            padding: 0;
        }
    }

    @if $type==sp {
        padding: 30px 20px;
    }

    @if $type==pc {
        padding: 50px 40px;
        max-width: 873px;
    }

    .title {
        @if $type=="" {
            text-align: center;
            font-weight: bold;
        }

        @if $type==sp {
            font-size: 18px;
            margin-bottom: 20px;
        }

        @if $type==pc {
            font-size: 20px;
            margin-bottom: 35px;
        }
    }

    .text {
        @if $type=="" {}

        @if $type==sp {
            margin-top: 15px;
        }

        @if $type==pc {
            font-size: 15px;
            margin-top: 15px;
            text-align: center;
        }
    }

    .detail {
        @if $type==sp {
            font-size: 12px;
            margin-top: 30px;
        }

        @if $type==pc {
            font-size: 13px;
            margin-top: 30px;
        }
    }

    figure {
        @if $type=="" {}

        @if $type==sp {
            margin-top: 15px;
        }

        @if $type==pc {
            margin-top: 15px;
            text-align: center;
        }
    }

    .column {
        @if $type==sp {
            margin-top: 30px;

            div {
                +div {
                    margin-top: 20px;
                }

                p {
                    font-size: 13px;
                }

                img {
                    display: block;
                    margin: 0 auto;
                }
            }
        }

        @if $type==pc {
            margin-top: 50px;
            display: flex;
            gap: 35px;

            >div {
                width: calc(50% - 10px);

                p {
                    margin-top: 20px;
                }

                img {
                    display: block;
                    margin-left: auto;
                }
            }
        }
    }
}

.formWrap {
    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 60px;
    }
}

.thanks {
    color: $c_base;
}






.sample {
    @if $type=="" {}

    @if $type==sp {}

    @if $type==pc {}
}