/* --------------- variables --------------------- */
/* Break Point */
.breadcrumb {
  display: flex;
}
.breadcrumb a {
  color: #A5C957;
  text-decoration: underline;
}
.breadcrumb > span {
  display: inline-block;
  position: relative;
  color: #87878a;
  white-space: nowrap;
}
.breadcrumb > span:not(:last-of-type)::after {
  content: "";
  width: 4px;
  height: 4px;
  border: 0px;
  border-top: solid 1px #A5C957;
  border-right: solid 1px #A5C957;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 2px);
  right: -16px;
}
.breadcrumb > span:last-of-type {
  text-overflow: ellipsis;
  overflow: hidden;
}

.wp-pagenavi {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1;
}
.wp-pagenavi .page,
.wp-pagenavi .extend,
.wp-pagenavi .last,
.wp-pagenavi .first {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #fff;
  border-radius: 50%;
  color: #2C3A51;
}
.wp-pagenavi span.current {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #A5C957;
  color: #fff;
  border-radius: 50%;
}

.pageTitleWrap .pageTitle {
  font-weight: 400;
  font-family: "Unbounded", "Noto Sans JP", sans-serif;
}
.pageTitleWrap .pageTitle.jp {
  font-family: "Noto Sans JP", sans-serif;
}

main a {
  color: #2663B5;
  text-decoration: underline;
}
main a:hover {
  text-decoration: none;
}

.catList {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.catList .catItem {
  width: 25%;
}
.catList .catItem a {
  width: 100%;
  background-color: #F9F9FB;
  border: 1px solid #F2F2F2;
  color: #2C3A51;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-decoration: none;
}
.catList .catItem a.active {
  color: #fff;
  background-color: #2C3A51;
}

.contBtmLink a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background: rgb(44, 58, 81);
  background: linear-gradient(180deg, rgb(44, 58, 81) 0%, rgb(49, 49, 49) 100%);
}
.contBtmLink .enTitle {
  position: relative;
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  color: #fff;
  line-height: 1;
}
.contBtmLink .jpTitle {
  margin: 0;
}

.postH1 {
  font-weight: bold;
  line-height: 1.5;
}

.ymdcat {
  display: flex;
}
.ymdcat .ymd {
  font-weight: bold;
  color: #B2B2B2;
}
.ymdcat .cat {
  background-color: #fff;
  display: inline-block;
  border-radius: 24px;
}

.singleContents {
  line-height: 2;
}

.companyMapList .companyMap dl dt {
  color: #fff;
  display: inline-block;
  background-color: #2C3A51;
  min-width: 75px;
  border-radius: 17px;
  padding: 4px 10px;
  text-align: center;
}
.memberList .member .imgname figure {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.memberList .member .imgname figure img {
  width: 100%;
}
.memberList .member .imgname dl dt {
  font-weight: bold;
  color: #A5C957;
}
.memberList .member .imgname dl dd {
  font-weight: bold;
}
.aboutH2 {
  font-weight: bold;
  font-feature-settings: "palt";
}

.seizoukouteiWrap h3 {
  background-color: #2C3A51;
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.seizoukouteiWrap figure {
  text-align: center;
}

.serviceLinkList li {
  background-color: #fff;
}
.serviceLinkList li a {
  display: block;
  color: #2C3A51;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
}
.serviceLinkList li a .title {
  font-weight: bold;
}
.serviceLinkList li a .text {
  color: #888888;
}

.contactLinkList li {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.contactLinkList li a {
  display: block;
  color: #2C3A51;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
}
.contactLinkList li a .title {
  font-weight: bold;
}
.contactLinkList li a .text {
  color: #888888;
}

dl.awardsList {
  font-family: "Noto Sans JP", sans-serif !important;
}
dl.awardsList > div {
  background-color: #fff;
  border-radius: 5px;
}
dl.awardsList > div > dt {
  font-weight: 400;
}
dl.awardsList > div > dd {
  font-weight: 400;
}

.serviceWrap {
  position: relative;
}
.serviceWrap#culnetconsortium:before {
  background-color: #69B6E2;
}
.serviceWrap#culnetconsortium h3 {
  color: #69B6E2;
}
.serviceWrap#ocattebase:before {
  background-color: #D25E27;
}
.serviceWrap#ocattebase h3 {
  color: #D25E27;
}
.serviceWrap#pipeline:before {
  background-color: #2D3C52;
}
.serviceWrap#pipeline h3 {
  color: #2D3C52;
}
.serviceWrap#cellag:before {
  background-color: #F1EB6D;
}
.serviceWrap#cellag h3 {
  color: #BEB71B;
}
.serviceWrap .serviceDetailBox {
  border-radius: 18px;
  background-color: #fff;
  position: relative;
}
.serviceWrap .serviceDetailBox > h2 {
  text-align: center;
}
.serviceWrap .serviceDetailBox > h3 {
  text-align: center;
  font-weight: bold;
}
.serviceWrap .serviceDetailBox > figure {
  text-align: center;
}
.serviceWrap .serviceDetailBox .serviceImgList > div {
  background-color: #FFFEED;
  border-radius: 13px;
}
.serviceWrap .serviceDetailBox .serviceImgList > div h4 {
  color: #BEB71B;
  text-align: center;
  font-weight: bold;
}
.serviceTitle {
  font-weight: 400;
  font-family: "Unbounded", sans-serif;
  line-height: 1;
}

.modaal-container {
  border-radius: 9px;
}
.modaal-container .modaal-content-container {
  padding: 0;
}
.modaal-container .title {
  text-align: center;
  font-weight: bold;
}
.thanks {
  color: #A5C957;
}

.singleContents .has-inline-color.has-luminous-vivid-orange-color {
  color: #A5C957 !important;
}
.singleContents .inner > *:first-child {
  margin-top: 0;
}
.singleContents h2 {
  position: relative;
  font-weight: 500;
}
.singleContents h3 {
  border-bottom: 2px solid #A5C957;
  font-weight: 400;
  color: #222222;
}
.singleContents h4 {
  color: #A5C957;
}
.singleContents ul {
  padding-left: 0;
}
.singleContents ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
}
.singleContents ol li {
  position: relative;
}
.singleContents p + .wp-block-gallery {
  margin-top: 10px;
}
.singleContents .wp-block-button a, .singleContents .wp-block-button .wp-block-button__link {
  background-color: #fff;
  position: relative;
}
.singleContents .wp-block-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: inherit;
  text-align: left;
  border: 1px solid #E8E8E8;
}
.singleContents .wp-block-table thead {
  border-bottom: none;
}
.singleContents .wp-block-table tr th {
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #E8E8E8;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
}
.singleContents .wp-block-table tr th:last-child {
  margin-bottom: 0;
  border-right: none;
}
.singleContents .wp-block-table tr th.sub {
  background-color: #A5C957;
  color: #fff;
}
.singleContents .wp-block-table tr td {
  border: none;
  border-bottom: 1px solid #E8E8E8;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
  word-break: break-all;
  background-color: #fff;
}
.singleContents .wp-block-table tr td:last-child {
  margin-bottom: 0;
  border-right: none;
}
.singleContents .wp-block-table.is-style-stripes td:nth-child(odd) {
  background-color: #f3f3f3;
}
.singleContents .wp-block-columns .wp-block-image {
  margin-bottom: 0px;
  margin-top: 0;
}
.singleContents .wp-block-columns p {
  margin: 0;
}
.singleContents .wp-block-media-text .wp-block-media-text__content p {
  margin-top: 0;
}
.singleContents .wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
  background: none;
  color: #2C3A51;
  position: static;
  font-size: 12px;
}
.singleContents .wp-block-gallery.has-nested-images figure.wp-block-image {
  display: initial;
}
.singleContents .wp-block-gallery.has-nested-images figure.wp-block-image img {
  height: auto !important;
}
.singleContents .colorBox {
  background-color: #fff;
}
.singleContents .colorBox .cont p {
  margin: 0;
}
.singleContents .borderBox {
  border: 1px solid #555;
}
.singleContents .borderBox .cont p {
  margin: 0;
}

h2, h3, h4 {
  line-height: 1.5;
}

h2.title {
  position: relative;
  font-weight: 500;
}

h3.title {
  border-bottom: 2px solid #A5C957;
  font-weight: 400;
  color: #222222;
}

h4.title {
  color: #A5C957;
}

.h2 {
  position: relative;
  font-weight: 500;
}

ul.commonList {
  padding-left: 0;
}

ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
}
ol li {
  position: relative;
}

a.icon {
  display: inline-block;
  position: relative;
  padding-right: 25px;
}
a.icon:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  position: absolute;
  right: 0px;
  top: 0.2em;
}
a.icon[href$=pdf]:after {
  background-image: url(../images/common/icon_pdf.svg);
}
a.icon[href$=doc]:after, a.icon[href$=docx]:after {
  background-image: url(../images/common/icon_word.svg);
}
a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
  background-image: url(../images/common/icon_excel.svg);
}
a.icon.front {
  padding-left: 25px;
  padding-right: 0;
}
a.icon.front:after {
  left: 0px;
}

.iconBlank {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../images/common/icon_blank.svg") no-repeat 0 0;
  background-size: cover;
  vertical-align: middle;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

.commonBtn span {
  background-color: #fff;
  position: relative;
}

.backBtn span {
  background-color: #fff;
  position: relative;
}

.articleBtn {
  background-color: #fff;
  position: relative;
  text-decoration: none;
}

.imgLine2 li {
  text-align: center;
  padding: 0 !important;
}
.imgLine2 li:before {
  content: none !important;
}
.imgLine2 li img {
  max-width: 100%;
}
.imgLine2 .cap {
  display: block;
  font-size: 18px;
  margin-top: 10px;
  color: #222;
  text-align: left;
}

.imgLine3 li {
  text-align: center;
}
.imgLine3 li:before {
  content: none !important;
}
.imgLine3 li img {
  max-width: 100%;
}
.imgLine3 .cap {
  display: block;
  font-size: 18px;
  margin-top: 10px;
  color: #222;
  text-align: left;
}

.imgInP .img .cap {
  display: block;
  font-size: 12px;
  margin-top: 10px;
  color: #2C3A51;
  text-align: left;
}
.twoCol .cap {
  display: block;
  font-size: 12px;
  margin-top: 10px;
  color: #2C3A51;
  text-align: left;
}

.colorBox {
  background-color: #fff;
}
.colorBox .cont p {
  margin: 0;
}

.borderBox {
  border: 1px solid #555;
}
.borderBox .cont p {
  margin: 0;
}

.commonTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: inherit;
  text-align: left;
  border: 1px solid #E8E8E8;
}
.commonTable .notes {
  font-size: 13px;
}
.commonTable.is-style-stripes td:nth-of-type(odd) {
  background-color: #f3f3f3;
}
.commonTable th {
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #E8E8E8;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
}
.commonTable th:last-child {
  margin-bottom: 0;
  border-right: none;
}
.commonTable th.sub {
  background-color: #A5C957;
  color: #fff;
}
.commonTable td {
  border: none;
  border-bottom: 1px solid #E8E8E8;
  border-top: 1px solid #E8E8E8;
  border-left: 1px solid #E8E8E8;
  word-break: break-all;
  background-color: #fff;
}
.commonTable td:last-child {
  margin-bottom: 0;
  border-right: none;
}

.scrollTable {
  overflow: auto;
}
.scrollText {
  font-size: 12px;
  display: block;
  text-align: right;
  margin: 30px 0 -20px;
}

.iframeBox {
  position: relative;
  padding-bottom: 56.25%;
}
.iframeBox iframe, .iframeBox video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.ofh {
  overflow: hidden;
}

dl.commonList > div {
  background-color: #fff;
  border-radius: 5px;
}
dl.commonList > div > dd {
  font-weight: 400;
}

/* common class */
.pointerNone {
  cursor: default;
  text-decoration: none;
}

.taC {
  text-align: center !important;
}

.taL {
  text-align: left !important;
}

.taR {
  text-align: right !important;
}

.Center {
  margin: 0 auto !important;
}

.ovh {
  overflow: hidden !important;
}

.mw100 {
  max-width: 100% !important;
  width: auto !important;
}

.w100p {
  width: 100% !important;
}

.pT0 {
  padding-top: 0 !important;
}

.mB0 {
  margin-bottom: 0 !important;
}

.mB5 {
  margin-bottom: 5px !important;
}

.mB10 {
  margin-bottom: 10px !important;
}

.mB20 {
  margin-bottom: 20px !important;
}

.mB30 {
  margin-bottom: 30px !important;
}

.mB40 {
  margin-bottom: 40px !important;
}

.mB50 {
  margin-bottom: 50px !important;
}

.mT0 {
  margin-top: 0 !important;
}

.mT5 {
  margin-top: 5px !important;
}

.mT10 {
  margin-top: 10px !important;
}

.mT20 {
  margin-top: 20px !important;
}

.mT30 {
  margin-top: 30px !important;
}

.mT40 {
  margin-top: 40px !important;
}

.mT50 {
  margin-top: 50px !important;
}

.mT60 {
  margin-top: 60px !important;
}

.mL0 {
  margin-left: 0px !important;
}

.mL5 {
  margin-left: 5px !important;
}

.mL10 {
  margin-left: 10px !important;
}

.mL20 {
  margin-left: 20px !important;
}

.mL30 {
  margin-left: 30px !important;
}

.mL40 {
  margin-left: 40px !important;
}

.mL50 {
  margin-left: 50px !important;
}

.mR0 {
  margin-right: 0px !important;
}

.mR5 {
  margin-right: 5px !important;
}

.mR10 {
  margin-right: 10px !important;
}

.mR20 {
  margin-right: 20px !important;
}

.mR30 {
  margin-right: 30px !important;
}

.mR40 {
  margin-right: 40px !important;
}

.mR50 {
  margin-right: 50px !important;
}

.bold {
  font-weight: bold !important;
}

.underline {
  text-decoration: underline !important;
}

.bdb {
  border-bottom: 2px solid #004aa4;
}

.fontL {
  font-size: 1.2em;
}

.fontS {
  font-size: 0.8em;
}

.floatL {
  float: left;
}

.floatR {
  float: right;
}

.preBox {
  background: #eff0f4;
  border: 1px solid #A5C957;
  padding: 15px;
  box-sizing: border-box;
  clear: both;
  overflow: hidden;
  margin-top: 30px;
  font-family: Arial, sans-serif;
  line-height: 1.8;
}
.preBox pre {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align-last: auto;
}

@media screen and (max-width: 800px) {
  body {
    padding-top: 100px;
  }
  #header {
    width: calc(100% - 20px);
    height: 60px;
    top: 10px;
    left: 10px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  #header #menuBtn {
    top: 8px;
    right: 10px;
  }
  #header #logo {
    height: 100%;
    background-color: transparent;
  }
  .breadcrumb {
    padding-left: 20px;
  }
  .breadcrumb > span {
    font-size: 13px;
  }
  .breadcrumb > span + span {
    margin-left: 24px;
  }
  .breadcrumb > span:not(:last-of-type)::after {
    right: -13px;
  }
  .wp-pagenavi {
    margin-top: 80px;
    font-size: 14px;
  }
  .wp-pagenavi .page,
  .wp-pagenavi .extend,
  .wp-pagenavi .last,
  .wp-pagenavi .first {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  .wp-pagenavi span.current {
    width: 40px;
    height: 40px;
    margin: 0 10px;
  }
  .pageTitleWrap {
    margin: 30px auto 0;
    padding-left: 20px;
  }
  .pageTitleWrap .pageTitle {
    font-size: 36px;
  }
  .commonInner {
    padding: 0 20px;
  }
  #contents {
    padding: 37px 0 120px;
  }
  .catList {
    display: flex;
    border-radius: 10px;
    gap: 6px;
    padding: 5px;
  }
  .catList .catItem a {
    height: 32px;
    font-size: 11px;
    cursor: pointer;
    border-radius: 8px;
  }
  .newsList {
    margin-top: 24px;
  }
  .contBtmLink {
    padding: 0 20px 20px;
  }
  .contBtmLink a {
    height: 140px;
  }
  .contBtmLink a + a {
    margin-top: 10px;
  }
  .contBtmLink .enTitle {
    font-size: 28px;
    padding-left: 30px;
  }
  .contBtmLink .enTitle:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
  }
  .contBtmLink .jpTitle {
    font-size: 14px;
    margin-top: 15px;
  }
  .postH1 {
    font-size: 20px;
  }
  .ymdcat {
    margin-top: 20px;
    gap: 15px;
    align-items: center;
  }
  .ymdcat .ymd {
    font-size: 12px;
  }
  .ymdcat .cat {
    min-width: 84px;
    text-align: center;
    padding: 4px 15px;
    font-size: 10px;
  }
  .companyMapList {
    margin-top: 30px;
  }
  .companyMapList .companyMap + .companyMap {
    margin-top: 30px;
  }
  .companyMapList .companyMap dl dd {
    margin-top: 15px;
  }
  .companyMapList .companyMap .map {
    margin-top: 15px;
  }
  .companyMapList .companyMap .map iframe {
    width: 100%;
    height: 184px;
  }
  .memberList {
    margin-top: 30px;
  }
  .memberList .member + .member {
    margin-top: 30px;
  }
  .memberList .member .imgname {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 5px;
  }
  .memberList .member .imgname figure {
    width: calc(50% - 5px);
    border-radius: 14px;
    border: 3px solid #fff;
    overflow: hidden;
  }
  .memberList .member .imgname dl {
    width: calc(50% - 5px);
  }
  .memberList .member .imgname dl dt {
    font-size: 15px;
  }
  .memberList .member .imgname dl dd {
    font-size: 24px;
    margin-top: 8px;
  }
  .aboutH2 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .seizoukouteiWrap {
    margin-top: 30px;
  }
  .seizoukouteiWrap h3 {
    font-size: 22px;
    padding: 8px 30px;
    border-radius: 50px;
  }
  .seizoukouteiWrap figure {
    margin-top: 30px;
  }
  .serviceLinkList {
    padding: 0 20px;
  }
  .serviceLinkList li + li {
    margin-top: 10px;
  }
  .serviceLinkList li a {
    padding: 15px 12px 38px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .serviceLinkList li a:after {
    content: "";
    display: inline-block;
    width: 34px;
    height: 17px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 18px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 12px;
    right: 12px;
    transition: 0.5s;
  }
  .serviceLinkList li a figure {
    width: 100px;
    flex: none;
    padding-top: 15px;
  }
  .serviceLinkList li a .title {
    font-size: 15px;
  }
  .serviceLinkList li a .text {
    font-size: 12px;
    margin-top: 10px;
  }
  .contactLinkList {
    padding: 0 20px;
  }
  .contactLinkList li + li {
    margin-top: 20px;
  }
  .contactLinkList li a {
    padding: 15px 12px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .contactLinkList li a:after {
    content: "";
    display: inline-block;
    width: 34px;
    height: 17px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 18px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 17px;
    right: 12px;
    transition: 0.5s;
  }
  .contactLinkList li a figure {
    width: 100px;
    flex: none;
    padding-top: 15px;
  }
  .contactLinkList li a .title {
    font-size: 15px;
  }
  .contactLinkList li a .text {
    font-size: 12px;
    margin-top: 10px;
  }
  dl.awardsList {
    margin-top: 30px;
  }
  dl.awardsList > div {
    padding: 12px;
  }
  dl.awardsList > div + div {
    margin-top: 15px;
  }
  dl.awardsList > div > dd {
    margin-top: 5px;
  }
  .serviceWrap {
    margin-top: 60px;
    padding: 0 20px;
  }
  .serviceWrap:before {
    content: "";
    width: 100%;
    height: 350px;
    position: absolute;
    left: 0;
    top: calc(50% - 175px);
  }
  .serviceWrap .serviceDetailBox {
    padding: 40px 20px 50px;
  }
  .serviceWrap .serviceDetailBox.culnetconsortium:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_rensa.svg) no-repeat center/cover;
    width: 80px;
    height: 52px;
    position: absolute;
    top: -25px;
    right: -20px;
  }
  .serviceWrap .serviceDetailBox.culnetconsortium:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_rensa.svg) no-repeat center/cover;
    width: 80px;
    height: 52px;
    transform: scale(1, -1);
    position: absolute;
    bottom: -27px;
    left: -20px;
  }
  .serviceWrap .serviceDetailBox.ocattebase:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_kakushin.svg) no-repeat center/cover;
    width: 68px;
    height: 52px;
    position: absolute;
    top: -25px;
    right: -18px;
    transform: scale(-1, 1);
  }
  .serviceWrap .serviceDetailBox.ocattebase:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_kakushin.svg) no-repeat center/cover;
    width: 68px;
    height: 52px;
    position: absolute;
    bottom: -27px;
    left: -18px;
  }
  .serviceWrap .serviceDetailBox.pipeline:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_seichou.svg) no-repeat center/cover;
    width: 80px;
    height: 36px;
    position: absolute;
    top: -25px;
    right: -18px;
    transform: rotate(10deg);
  }
  .serviceWrap .serviceDetailBox.pipeline:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_seichou.svg) no-repeat center/cover;
    width: 80px;
    height: 36px;
    position: absolute;
    bottom: -27px;
    left: -18px;
    transform: rotate(-10deg);
  }
  .serviceWrap .serviceDetailBox.cellag:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_hirogari.svg) no-repeat center/cover;
    width: 80px;
    height: 33px;
    position: absolute;
    top: -25px;
    right: -18px;
    transform: rotate(10deg);
  }
  .serviceWrap .serviceDetailBox.cellag:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_hirogari.svg) no-repeat center/cover;
    width: 80px;
    height: 33px;
    position: absolute;
    bottom: -27px;
    left: -18px;
    transform: rotate(-10deg);
  }
  .serviceWrap .serviceDetailBox > h3 {
    margin-top: 30px;
    font-size: 22px;
  }
  .serviceWrap .serviceDetailBox > p {
    margin-top: 30px;
    font-size: 14px;
  }
  .serviceWrap .serviceDetailBox > figure {
    margin-top: 30px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList {
    margin-top: 30px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div {
    padding: 17px 20px 15px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div + div {
    margin-top: 20px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div h4 {
    font-size: 16px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div p {
    margin-top: 15px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div figure {
    margin-top: 15px;
  }
  .serviceTitle {
    margin-top: 90px;
    font-size: 28px;
  }
  .serviceTitle + .h2sub {
    font-size: 15px;
    margin-top: 5px;
  }
  .businessArea {
    margin-top: 30px;
  }
  .modaal-container {
    padding: 30px 20px;
  }
  .modaal-container .title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .modaal-container .text {
    margin-top: 15px;
  }
  .modaal-container .detail {
    font-size: 12px;
    margin-top: 30px;
  }
  .modaal-container figure {
    margin-top: 15px;
  }
  .modaal-container .column {
    margin-top: 30px;
  }
  .modaal-container .column div + div {
    margin-top: 20px;
  }
  .modaal-container .column div p {
    font-size: 13px;
  }
  .modaal-container .column div img {
    display: block;
    margin: 0 auto;
  }
  .formWrap {
    margin-top: 30px;
  }
  .thanks {
    color: #A5C957;
  }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #A5C957 !important;
  }
  .singleContents .inner > *:first-child {
    margin-top: 0;
  }
  .singleContents p {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .singleContents h3 + p, .singleContents h4 + p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .singleContents * + h2 {
    margin-top: 60px;
  }
  .singleContents * + h3 {
    margin-top: 40px;
  }
  .singleContents * + h4 {
    margin-top: 30px;
  }
  .singleContents h2 {
    font-size: 24px;
    padding-left: 30px;
  }
  .singleContents h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 10px;
  }
  .singleContents h2.eng {
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 28px;
  }
  .singleContents h2.eng:before {
    top: 12px;
  }
  .singleContents h3 {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .singleContents h4 {
    font-size: 16px;
  }
  .singleContents ul li {
    padding-left: 20px;
    position: relative;
  }
  .singleContents ul li:not(:first-of-type) {
    margin-top: 10px;
  }
  .singleContents ul li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    background: #A5C957;
    position: absolute;
    margin: auto;
    top: 10px;
    left: 0px;
    border-radius: 50%;
  }
  .singleContents ol li {
    padding-left: 15px;
    counter-increment: mycounter;
  }
  .singleContents ol li:before {
    content: counter(mycounter) ".";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: #898989;
    font-weight: bold;
  }
  .singleContents ol li:not(:first-of-type) {
    margin-top: 10px;
  }
  .singleContents * + ul, .singleContents * + ol {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px;
  }
  .singleContents .wp-block-button {
    margin-bottom: 30px;
  }
  .singleContents .wp-block-button a, .singleContents .wp-block-button .wp-block-button__link {
    display: inline-block;
    border-radius: 22px;
    min-width: 172px;
    height: 40px;
    text-align: center;
    color: #2C3A51;
    font-size: 14px;
    padding: 5px 20px 5px 30px;
    margin: 0 auto;
  }
  .singleContents .wp-block-button a:after, .singleContents .wp-block-button .wp-block-button__link:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: -30px;
    top: 0;
  }
  .singleContents .wp-block-buttons {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .singleContents .wp-block-table {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .singleContents .wp-block-table tr th {
    padding: 10px 10px;
  }
  .singleContents .wp-block-table tr td {
    padding: 10px 10px;
  }
  .singleContents .wp-block-image {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .singleContents .wp-block-media-text .wp-block-media-text__content {
    margin-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  .singleContents .wp-block-media-text {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .singleContents .wp-block-group, .singleContents .wp-block-columns {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .singleContents .wp-block-gallery + .wp-block-gallery {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .singleContents .colorBox {
    padding: 20px 20px;
  }
  .singleContents .colorBox .title {
    font-weight: bold;
    font-size: 15px;
    position: relative;
  }
  .singleContents .colorBox * + .title {
    margin-top: 10px;
  }
  .singleContents .colorBox .cont {
    margin-top: 10px;
  }
  .singleContents .colorBox .cont * + p {
    margin: 10px 0 0;
  }
  .singleContents .borderBox {
    padding: 20px 20px;
  }
  .singleContents .borderBox .title {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
  }
  .singleContents .borderBox .title:before {
    content: "";
    display: inline-block;
    background-color: #a89f9b;
    width: 42px;
    height: 1px;
    position: absolute;
    left: calc(50% - 21px);
    bottom: 0px;
  }
  .singleContents .borderBox * + .title {
    margin-top: 30px;
  }
  .singleContents .borderBox .cont {
    margin-top: 20px;
  }
  .singleContents .borderBox .cont * + p {
    margin: 10px 0 0;
  }
  * + p {
    margin-top: 20px;
  }
  * + p.wide {
    margin-top: 30px;
  }
  h2, h3, h4 {
    line-height: 1.5;
  }
  h3 + p, h4 + p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  h3 + .commonList {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  * + h2.title, * + .h2 {
    margin-top: 60px;
  }
  * + h3.title {
    margin-top: 40px;
  }
  * + h4.title {
    margin-top: 30px;
  }
  h2.title {
    font-size: 24px;
    padding-left: 30px;
  }
  h2.title:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 10px;
  }
  h2.title.eng {
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 28px;
  }
  h2.title.eng:before {
    top: 12px;
  }
  h3.title {
    font-size: 20px;
    padding-bottom: 20px;
  }
  h4.title {
    font-size: 16px;
  }
  .h2 {
    font-size: 24px;
    padding-left: 30px;
  }
  .h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 10px;
  }
  .h2.eng {
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 28px;
  }
  .h2.eng:before {
    top: 12px;
  }
  ul.commonList li {
    padding-left: 20px;
    position: relative;
  }
  ul.commonList li:not(:first-of-type) {
    margin-top: 10px;
  }
  ul.commonList li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    background: #A5C957;
    position: absolute;
    margin: auto;
    top: 10px;
    left: 0px;
    border-radius: 50%;
  }
  ol li {
    padding-left: 15px;
    counter-increment: mycounter;
  }
  ol li:before {
    content: counter(mycounter) ".";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: #898989;
    font-weight: bold;
  }
  ol li:not(:first-of-type) {
    margin-top: 10px;
  }
  section + section {
    margin-top: 70px;
  }
  * + .commonList, * + .linkList, * + .colorBox, * + .borderBox, * + .commonTable {
    margin-top: 30px;
  }
  * + .borderBox {
    margin-top: 30px;
  }
  * + ol {
    margin-top: 30px;
  }
  * + .imgLine2, * + .imgLine3, * + .twoCol {
    margin-top: 30px;
  }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;
  }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: 0.2em;
  }
  a.icon[href$=pdf]:after {
    background-image: url(../images/common/icon_pdf.svg);
  }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/common/icon_word.svg);
  }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/common/icon_excel.svg);
  }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0;
  }
  a.icon.front:after {
    left: 0px;
  }
  .iconBlank {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
  .commonBtn {
    display: inline-block;
    position: relative;
    padding-right: 30px;
    text-decoration: none;
  }
  .commonBtn:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
  }
  .commonBtn:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .commonBtn span {
    display: flex;
    border-radius: 22px;
    min-width: 172px;
    height: 40px;
    align-items: center;
    justify-content: center;
    color: #2C3A51;
    font-size: 14px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .commonBtn span:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .backBtn {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    text-decoration: none;
  }
  .backBtn:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    transform: scale(-1, 1);
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .backBtn:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .backBtn span {
    display: flex;
    border-radius: 22px;
    min-width: 172px;
    height: 40px;
    align-items: center;
    justify-content: center;
    color: #2C3A51;
    font-size: 14px;
    padding: 0 20px;
    margin: 0 auto;
  }
  .backBtn span:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .articleBtn {
    display: inline-block;
    border-radius: 22px;
    min-width: 172px;
    height: 40px;
    text-align: center;
    color: #2C3A51;
    font-size: 14px;
    padding: 5px 20px 5px 30px;
    margin: 0 auto;
  }
  .articleBtn:after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: -30px;
    top: 0;
  }
  .btnWrap {
    margin-top: 90px;
    text-align: center;
  }
  p .commonBtn + .commonBtn {
    margin-top: 20px;
  }
  .imgLine2.spflex {
    display: flex;
  }
  .imgLine2.spflex li {
    margin: 0;
    width: calc(50% - 8px);
  }
  .imgLine2.spflex li:nth-of-type(odd) {
    margin-right: 16px;
  }
  .imgLine2 li {
    margin-top: 10px !important;
  }
  .imgLine2 li img {
    max-width: 100%;
  }
  .imgLine2 .cap {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    color: #222;
    text-align: left;
    font-size: 14px;
  }
  .imgLine3 li {
    margin-top: 10px !important;
    padding: 0 0 30px;
  }
  .imgLine3 li img {
    max-width: 100%;
  }
  .imgLine3 .cap {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    color: #222;
    text-align: left;
    font-size: 14px;
  }
  .imgInP .img {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .imgInP .img .cap {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #2C3A51;
    text-align: left;
    font-size: 12px;
  }
  .imgInP.sp_img_btm {
    display: flex;
    flex-wrap: wrap;
  }
  .imgInP.sp_img_btm .img {
    display: block;
    order: 2;
    margin: 20px auto 0;
    padding-bottom: 0;
  }
  .imgInP.sp_img_btm .text {
    flex-basis: 100%;
    order: 1;
  }
  .twoCol .txt *:nth-child(1) {
    margin-top: 0;
  }
  .twoCol .txt + .img {
    margin-top: 30px;
  }
  .twoCol .txt + .txt {
    margin-top: 30px;
  }
  .twoCol .img {
    width: 100%;
    text-align: center;
  }
  .twoCol .img + .img {
    margin-top: 30px;
  }
  .twoCol .img + .txt {
    margin-top: 30px;
  }
  .twoCol .img img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .twoCol .cap {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #2C3A51;
    text-align: left;
    font-size: 12px;
  }
  .colorBox {
    padding: 20px 20px;
  }
  .colorBox .title {
    font-weight: bold;
    font-size: 15px;
    position: relative;
  }
  .colorBox * + .title {
    margin-top: 10px;
  }
  .colorBox .cont {
    margin-top: 10px;
  }
  .colorBox .cont * + p {
    margin: 10px 0 0;
  }
  .borderBox {
    padding: 20px 20px;
  }
  .borderBox .title {
    font-weight: bold;
    font-size: 16px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
  }
  .borderBox .title:before {
    content: "";
    display: inline-block;
    background-color: #a89f9b;
    width: 42px;
    height: 1px;
    position: absolute;
    left: calc(50% - 21px);
    bottom: 0px;
  }
  .borderBox * + .title {
    margin-top: 30px;
  }
  .borderBox .cont {
    margin-top: 20px;
  }
  .borderBox .cont * + p {
    margin: 10px 0 0;
  }
  .commonTable td.wFix,
  .commonTable th.wFix {
    width: 120px;
  }
  .commonTable th {
    padding: 10px 10px;
  }
  .commonTable td {
    padding: 10px 10px;
  }
  .scrollTable {
    margin-top: 30px;
  }
  .scrollTable table {
    white-space: nowrap;
  }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
  }
  .iframeBox {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 30px;
  }
  .iframeBox iframe, .iframeBox video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
  * + dl.commonList {
    margin-top: 30px;
  }
  dl.commonList > div {
    padding: 12px;
  }
  dl.commonList > div + div {
    margin-top: 15px;
  }
  dl.commonList > div > dd {
    margin-top: 5px;
  }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none;
  }
  .taC {
    text-align: center !important;
  }
  .taL {
    text-align: left !important;
  }
  .taR {
    text-align: right !important;
  }
  .Center {
    margin: 0 auto !important;
  }
  .ovh {
    overflow: hidden !important;
  }
  .mw100 {
    max-width: 100% !important;
    width: auto !important;
  }
  .w100p {
    width: 100% !important;
  }
  .pT0 {
    padding-top: 0 !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5 {
    margin-bottom: 5px !important;
  }
  .mB10 {
    margin-bottom: 10px !important;
  }
  .mB20 {
    margin-bottom: 20px !important;
  }
  .mB30 {
    margin-bottom: 30px !important;
  }
  .mB40 {
    margin-bottom: 40px !important;
  }
  .mB50 {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5 {
    margin-top: 5px !important;
  }
  .mT10 {
    margin-top: 10px !important;
  }
  .mT20 {
    margin-top: 20px !important;
  }
  .mT30 {
    margin-top: 30px !important;
  }
  .mT40 {
    margin-top: 40px !important;
  }
  .mT50 {
    margin-top: 50px !important;
  }
  .mT60 {
    margin-top: 60px !important;
  }
  .mL0 {
    margin-left: 0px !important;
  }
  .mL5 {
    margin-left: 5px !important;
  }
  .mL10 {
    margin-left: 10px !important;
  }
  .mL20 {
    margin-left: 20px !important;
  }
  .mL30 {
    margin-left: 30px !important;
  }
  .mL40 {
    margin-left: 40px !important;
  }
  .mL50 {
    margin-left: 50px !important;
  }
  .mR0 {
    margin-right: 0px !important;
  }
  .mR5 {
    margin-right: 5px !important;
  }
  .mR10 {
    margin-right: 10px !important;
  }
  .mR20 {
    margin-right: 20px !important;
  }
  .mR30 {
    margin-right: 30px !important;
  }
  .mR40 {
    margin-right: 40px !important;
  }
  .mR50 {
    margin-right: 50px !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5_sp {
    margin-bottom: 5px !important;
  }
  .mB10_sp {
    margin-bottom: 10px !important;
  }
  .mB20_sp {
    margin-bottom: 20px !important;
  }
  .mB30_sp {
    margin-bottom: 30px !important;
  }
  .mB40_sp {
    margin-bottom: 40px !important;
  }
  .mB50_sp {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5_sp {
    margin-top: 5px !important;
  }
  .mT10_sp {
    margin-top: 10px !important;
  }
  .mT20_sp {
    margin-top: 20px !important;
  }
  .mT30_sp {
    margin-top: 30px !important;
  }
  .mT40_sp {
    margin-top: 40px !important;
  }
  .mT50_sp {
    margin-top: 50px !important;
  }
  .mL0_sp {
    margin-left: 0px !important;
  }
  .mL5_sp {
    margin-left: 5px !important;
  }
  .mL10_sp {
    margin-left: 10px !important;
  }
  .mL20_sp {
    margin-left: 20px !important;
  }
  .mL30_sp {
    margin-left: 30px !important;
  }
  .mL40_sp {
    margin-left: 40px !important;
  }
  .mL50_sp {
    margin-left: 50px !important;
  }
  .mR0_sp {
    margin-right: 0px !important;
  }
  .mR5_sp {
    margin-right: 5px !important;
  }
  .mR10_sp {
    margin-right: 10px !important;
  }
  .mR20_sp {
    margin-right: 20px !important;
  }
  .mR30_sp {
    margin-right: 30px !important;
  }
  .mR40_sp {
    margin-right: 40px !important;
  }
  .mR50_sp {
    margin-right: 50px !important;
  }
  .size12_sp {
    font-size: 12px !important;
  }
  .size14_sp {
    font-size: 14px !important;
  }
  .size16_sp {
    font-size: 16px !important;
  }
  .size18_sp {
    font-size: 18px !important;
  }
  .size20_sp {
    font-size: 20px !important;
  }
  .w50p_sp {
    width: 50% !important;
  }
  .taL_sp {
    text-align: left !important;
  }
  .taC_sp {
    text-align: center !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .underline {
    text-decoration: underline !important;
  }
  .bdb {
    border-bottom: 2px solid #004aa4;
  }
  .fontL {
    font-size: 1.2em;
  }
  .fontS {
    font-size: 0.8em;
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .preBox {
    background: #eff0f4;
    border: 1px solid #A5C957;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
  }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}
@media print, screen and (min-width: 801px) {
  body {
    padding-top: 126px;
  }
  #header {
    width: calc(100% - 60px);
    height: 74px;
    border-radius: 52px;
    background-color: #fff;
    left: 30px;
    top: 13px;
  }
  #header #logo {
    background-color: transparent;
    height: 100%;
  }
  #header #logo img {
    max-width: 200px;
  }
  #header #globalMenu ul a:before {
    top: 15px;
  }
  #header #globalMenu ul a:after {
    bottom: 0;
  }
  .breadcrumb {
    width: calc(100% - 60px);
    margin: 0 auto;
    padding-left: 60px;
  }
  .breadcrumb a:hover {
    text-decoration: none;
  }
  .breadcrumb > span {
    font-size: 13px;
  }
  .breadcrumb > span + span {
    margin-left: 30px;
  }
  .wp-pagenavi {
    margin-top: 80px;
    font-size: 15px;
  }
  .wp-pagenavi .page,
  .wp-pagenavi .extend,
  .wp-pagenavi .last,
  .wp-pagenavi .first {
    width: 43px;
    height: 43px;
    margin: 0 10px;
    transition: 0.5s;
  }
  .wp-pagenavi .page:hover,
  .wp-pagenavi .extend:hover,
  .wp-pagenavi .last:hover,
  .wp-pagenavi .first:hover {
    background-color: #EBEBEB;
    color: #A5C957;
  }
  .wp-pagenavi span.current {
    width: 43px;
    height: 43px;
    margin: 0 10px;
  }
  .pageTitleWrap {
    width: calc(100% - 60px);
    margin: 35px auto 0;
    padding-left: 60px;
  }
  .pageTitleWrap .pageTitle {
    font-size: 35px;
  }
  .commonInner {
    max-width: 994px;
    width: 90%;
    margin: 0 auto;
  }
  #contents {
    padding: 40px 0 130px;
  }
  .catList {
    display: flex;
    border-radius: 30px;
    gap: 20px;
    padding: 5px 6px;
    max-width: 874px;
    margin: 0 auto;
  }
  .catList .catItem a {
    height: 48px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 24px;
  }
  .catList .catItem a:hover {
    color: #fff;
    background-color: #2C3A51;
  }
  .newsList {
    margin-top: 57px;
  }
  .contBtmLink {
    display: flex;
    gap: 2px;
  }
  .contBtmLink a {
    width: calc(50% - 1px);
    height: 340px;
    transition: 0.5s;
  }
  .contBtmLink a:hover {
    filter: brightness(1.4);
  }
  .contBtmLink .enTitle {
    margin: 0 auto;
    font-size: 35px;
    padding-left: 40px;
  }
  .contBtmLink .enTitle:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
  }
  .contBtmLink .jpTitle {
    font-size: 20px;
    margin-top: 12px;
  }
  .postH1 {
    font-size: 18px;
  }
  .ymdcat {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
  }
  .ymdcat .ymd {
    font-size: 13px;
  }
  .ymdcat .cat {
    min-width: 97px;
    text-align: center;
    padding: 6px 15px;
    font-size: 11px;
    margin-top: 15px;
  }
  .singleContents {
    font-size: 14px;
  }
  .companyMapList {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
  }
  .companyMapList .companyMap {
    width: calc(50% - 25px);
  }
  .companyMapList .companyMap dl {
    display: flex;
    align-items: flex-start;
  }
  .companyMapList .companyMap dl dt {
    flex: none;
  }
  .companyMapList .companyMap dl dd {
    margin-left: 13px;
  }
  .companyMapList .companyMap .map {
    margin-top: 15px;
  }
  .companyMapList .companyMap .map iframe {
    width: 100%;
    height: 250px;
  }
  .memberList {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 39px;
  }
  .memberList .member {
    width: calc(33.3333333333% - 26px);
  }
  .memberList .member .imgname figure {
    border-radius: 24px;
    border: 5px solid #fff;
    overflow: hidden;
  }
  .memberList .member .imgname dl {
    margin-top: 21px;
  }
  .memberList .member .imgname dl dd {
    font-size: 26px;
    margin-top: 8px;
  }
  .memberList .member p {
    font-size: 13px;
    margin-top: 25px;
  }
  .aboutH2 {
    font-size: 27px;
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .seizoukouteiWrap {
    max-width: 868px;
    margin: 65px auto 0;
  }
  .seizoukouteiWrap h3 {
    font-size: 24px;
    padding: 8px 30px;
    border-radius: 28px;
  }
  .seizoukouteiWrap figure {
    margin-top: 50px;
  }
  .serviceLinkList {
    max-width: 1223px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 12px;
  }
  .serviceLinkList li {
    text-align: center;
    width: calc(25% - 9px);
  }
  .serviceLinkList li a {
    padding: 30px 20px 50px;
  }
  .serviceLinkList li a:after {
    content: "";
    display: inline-block;
    width: 47px;
    height: 24px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: 0.5s;
  }
  .serviceLinkList li a:hover:after {
    background: url(../images/common/arrow_l.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #fff;
  }
  .serviceLinkList li a figure img {
    max-height: 40px;
  }
  .serviceLinkList li a .title {
    font-size: 15px;
    margin-top: 20px;
  }
  .serviceLinkList li a .text {
    font-size: 12px;
    margin-top: 15px;
  }
  .contactLinkList {
    max-width: 1223px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 18px;
  }
  .contactLinkList li {
    width: calc(50% - 9px);
  }
  .contactLinkList li a {
    padding: 30px 20px;
  }
  .contactLinkList li a:after {
    content: "";
    display: inline-block;
    width: 47px;
    height: 24px;
    border-radius: 22px;
    background: url(../images/common/arrow_l_w.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #2C3A51;
    border: 1px solid #2C3A51;
    position: absolute;
    bottom: 30px;
    right: 10px;
    transition: 0.5s;
  }
  .contactLinkList li a:hover:after {
    background: url(../images/common/arrow_l.svg) no-repeat center/cover;
    background-size: 20px auto;
    background-color: #fff;
  }
  .contactLinkList li a figure img {
    max-height: 40px;
  }
  .contactLinkList li a .title {
    font-size: 15px;
    margin-top: 20px;
  }
  .contactLinkList li a .text {
    font-size: 12px;
    margin-top: 15px;
  }
  dl.awardsList {
    margin-top: 25px;
  }
  dl.awardsList > div {
    display: flex;
    padding: 13px 25px;
  }
  dl.awardsList > div + div {
    margin-top: 10px;
  }
  dl.awardsList > div > dt {
    width: 50px;
    flex: none;
  }
  dl.awardsList > div > dd {
    padding-left: 38px;
  }
  dl.awardsList > div > dd:nth-of-type(1) {
    width: calc(60% - 50px);
  }
  dl.awardsList > div > dd:nth-of-type(2) {
    width: 40%;
  }
  .serviceWrap {
    margin-top: 125px;
  }
  .serviceWrap:before {
    content: "";
    width: 100%;
    height: 450px;
    position: absolute;
    left: 0;
    top: calc(50% - 225px);
  }
  .serviceWrap .serviceDetailBox {
    padding: 50px;
    max-width: 1000px;
    margin: 0 auto;
  }
  .serviceWrap .serviceDetailBox.culnetconsortium:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_rensa.svg) no-repeat center/cover;
    width: 174px;
    height: 113px;
    position: absolute;
    top: -58px;
    right: -60px;
  }
  .serviceWrap .serviceDetailBox.culnetconsortium:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_rensa.svg) no-repeat center/cover;
    width: 174px;
    height: 113px;
    transform: scale(1, -1);
    position: absolute;
    bottom: -74px;
    left: -80px;
  }
  .serviceWrap .serviceDetailBox.ocattebase:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_kakushin.svg) no-repeat center/cover;
    width: 136px;
    height: 104px;
    position: absolute;
    top: -68px;
    right: -52px;
    transform: scale(-1, 1);
  }
  .serviceWrap .serviceDetailBox.ocattebase:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_kakushin.svg) no-repeat center/cover;
    width: 136px;
    height: 104px;
    position: absolute;
    bottom: -29px;
    left: -68px;
  }
  .serviceWrap .serviceDetailBox.pipeline:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_seichou.svg) no-repeat center/cover;
    width: 111px;
    height: 50px;
    position: absolute;
    top: -10px;
    right: -32px;
    transform: rotate(10deg);
  }
  .serviceWrap .serviceDetailBox.pipeline:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_seichou.svg) no-repeat center/cover;
    width: 111px;
    height: 50px;
    position: absolute;
    bottom: -10px;
    left: -32px;
    transform: rotate(-10deg);
  }
  .serviceWrap .serviceDetailBox.cellag:before {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_hirogari.svg) no-repeat center/cover;
    width: 112px;
    height: 47px;
    position: absolute;
    top: -27px;
    right: -45px;
    transform: rotate(10deg);
  }
  .serviceWrap .serviceDetailBox.cellag:after {
    content: "";
    display: inline-block;
    background: url(../images/common/mark_hirogari.svg) no-repeat center/cover;
    width: 112px;
    height: 47px;
    position: absolute;
    bottom: -27px;
    left: -45px;
    transform: rotate(-10deg);
  }
  .serviceWrap .serviceDetailBox > h3 {
    margin-top: 30px;
    font-size: 22px;
  }
  .serviceWrap .serviceDetailBox > p {
    margin-top: 30px;
    font-size: 14px;
    text-align: center;
  }
  .serviceWrap .serviceDetailBox > figure {
    margin-top: 40px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList {
    margin-top: 40px;
    display: flex;
    gap: 30px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div {
    padding: 17px 20px 15px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div h4 {
    font-size: 18px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div p {
    margin-top: 15px;
  }
  .serviceWrap .serviceDetailBox .serviceImgList > div figure {
    margin-top: 20px;
  }
  .serviceTitle {
    margin-top: 120px;
    font-size: 35px;
  }
  .serviceTitle + .h2sub {
    font-size: 20px;
    margin-top: 5px;
  }
  .businessArea {
    margin-top: 50px;
  }
  .modaal-container {
    padding: 50px 40px;
    max-width: 873px;
  }
  .modaal-container .title {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .modaal-container .text {
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
  }
  .modaal-container .detail {
    font-size: 13px;
    margin-top: 30px;
  }
  .modaal-container figure {
    margin-top: 15px;
    text-align: center;
  }
  .modaal-container .column {
    margin-top: 50px;
    display: flex;
    gap: 35px;
  }
  .modaal-container .column > div {
    width: calc(50% - 10px);
  }
  .modaal-container .column > div p {
    margin-top: 20px;
  }
  .modaal-container .column > div img {
    display: block;
    margin-left: auto;
  }
  .formWrap {
    margin-top: 60px;
  }
  .thanks {
    color: #A5C957;
  }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #A5C957 !important;
  }
  .singleContents .inner > *:first-child {
    margin-top: 0;
  }
  .singleContents p {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .singleContents h3 + p, .singleContents h4 + p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .singleContents * + h2 {
    margin-top: 90px;
  }
  .singleContents * + h3 {
    margin-top: 60px;
  }
  .singleContents * + h4 {
    margin-top: 50px;
  }
  .singleContents h2 {
    font-size: 30px;
    padding-left: 40px;
  }
  .singleContents h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 13px;
  }
  .singleContents h2.eng {
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 38px;
  }
  .singleContents h2.eng:before {
    top: 17px;
  }
  .singleContents h3 {
    font-size: 24px;
    padding-bottom: 20px;
  }
  .singleContents h4 {
    font-size: 20px;
  }
  .singleContents ul li {
    padding-left: 30px;
    position: relative;
  }
  .singleContents ul li:not(:first-of-type) {
    margin-top: 15px;
  }
  .singleContents ul li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    background: #A5C957;
    position: absolute;
    margin: auto;
    top: 8px;
    left: 9px;
    border-radius: 50%;
  }
  .singleContents ol li {
    padding-left: 20px;
    counter-increment: mycounter;
  }
  .singleContents ol li:before {
    content: counter(mycounter) ".";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: #898989;
    font-weight: bold;
  }
  .singleContents ol li:not(:first-of-type) {
    margin-top: 15px;
  }
  .singleContents * + ul, .singleContents * + ol {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px;
  }
  .singleContents .wp-block-button {
    margin-bottom: 50px;
  }
  .singleContents .wp-block-button a, .singleContents .wp-block-button .wp-block-button__link {
    display: inline-block;
    border-radius: 22px;
    min-width: 182px;
    height: 44px;
    text-align: center;
    color: #2C3A51;
    font-size: 14px;
    padding: 7px 20px 7px 34px;
    transition: 0.5s;
  }
  .singleContents .wp-block-button a:after, .singleContents .wp-block-button .wp-block-button__link:after {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: -34px;
    top: 0;
    transition: 0.5s;
  }
  .singleContents .wp-block-button a:hover, .singleContents .wp-block-button .wp-block-button__link:hover {
    border-radius: 22px 0 0 22px;
    background-color: #FED501;
  }
  .singleContents .wp-block-button a:hover:after, .singleContents .wp-block-button .wp-block-button__link:hover:after {
    border-radius: 0 50% 50% 0;
    background-color: #FED501;
  }
  .singleContents .wp-block-buttons {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .singleContents .wp-block-table {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .singleContents .wp-block-table tr th {
    padding: 15px 20px;
  }
  .singleContents .wp-block-table tr td {
    padding: 15px 20px;
  }
  .singleContents .wp-block-image {
    margin-bottom: 70px;
    margin-top: 50px;
  }
  .singleContents .wp-block-media-text {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .singleContents .wp-block-group, .singleContents .wp-block-columns {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .singleContents .wp-block-gallery + .wp-block-gallery {
    margin-bottom: 70px;
    margin-top: 50px;
  }
  .singleContents .colorBox {
    padding: 40px 30px;
  }
  .singleContents .colorBox .title {
    font-weight: bold;
    font-size: 18px;
    position: relative;
  }
  .singleContents .colorBox * + .title {
    margin-top: 10px;
  }
  .singleContents .colorBox .cont {
    margin-top: 15px;
  }
  .singleContents .colorBox .cont * + p {
    margin: 10px 0 0;
  }
  .singleContents .borderBox {
    padding: 40px 80px;
  }
  .singleContents .borderBox .title {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
  }
  .singleContents .borderBox .title:before {
    content: "";
    display: inline-block;
    background-color: #a89f9b;
    width: 42px;
    height: 1px;
    position: absolute;
    left: calc(50% - 21px);
    bottom: 0px;
  }
  .singleContents .borderBox * + .title {
    margin-top: 30px;
  }
  .singleContents .borderBox .cont {
    margin-top: 30px;
  }
  .singleContents .borderBox .cont * + p {
    margin: 10px 0 0;
  }
  * + p {
    margin-top: 30px;
  }
  * + p.wide {
    margin-top: 60px;
  }
  h2, h3, h4 {
    line-height: 1.5;
  }
  h3 + p, h4 + p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h3 + .commonList {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  * + h2.title, * + .h2 {
    margin-top: 90px;
  }
  * + h3.title {
    margin-top: 60px;
  }
  * + h4.title {
    margin-top: 50px;
  }
  h2.title {
    font-size: 30px;
    padding-left: 40px;
  }
  h2.title:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 13px;
  }
  h2.title.eng {
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 38px;
  }
  h2.title.eng:before {
    top: 17px;
  }
  h3.title {
    font-size: 24px;
    padding-bottom: 20px;
  }
  h4.title {
    font-size: 20px;
  }
  .h2 {
    font-size: 30px;
    padding-left: 40px;
  }
  .h2:before {
    content: "";
    display: inline-block;
    background: url(../images/common/midashi_mark.svg) no-repeat center/cover;
    width: 23px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 13px;
  }
  .h2.eng {
    font-family: "Unbounded", sans-serif;
    font-weight: 400;
    font-size: 38px;
  }
  .h2.eng:before {
    top: 17px;
  }
  ul.commonList li {
    padding-left: 30px;
    position: relative;
  }
  ul.commonList li:not(:first-of-type) {
    margin-top: 15px;
  }
  ul.commonList li:before {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    background: #A5C957;
    position: absolute;
    margin: auto;
    top: 8px;
    left: 9px;
    border-radius: 50%;
  }
  ol li {
    padding-left: 20px;
    counter-increment: mycounter;
  }
  ol li:before {
    content: counter(mycounter) ".";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: #898989;
    font-weight: bold;
  }
  ol li:not(:first-of-type) {
    margin-top: 15px;
  }
  section + section {
    margin-top: 90px;
  }
  * + .commonList, * + .linkList, * + .colorBox, * + .borderBox, * + .commonTable {
    margin-top: 40px;
  }
  * + .borderBox {
    margin-top: 40px;
  }
  * + ol {
    margin-top: 40px;
  }
  * + .imgLine2, * + .imgLine3, * + .twoCol {
    margin-top: 40px;
  }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;
  }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: 0.2em;
  }
  a.icon[href$=pdf]:after {
    background-image: url(../images/common/icon_pdf.svg);
  }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/common/icon_word.svg);
  }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/common/icon_excel.svg);
  }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0;
  }
  a.icon.front:after {
    left: 0px;
  }
  .iconBlank {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
  .commonBtn {
    display: inline-block;
    position: relative;
    padding-right: 34px;
    text-decoration: none;
  }
  .commonBtn:after {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
  }
  .commonBtn:before {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .commonBtn:hover:after {
    border-radius: 0 50% 50% 0;
    background-color: #FED501;
  }
  .commonBtn:hover:before {
    border-radius: 0 50% 50% 0;
  }
  .commonBtn:hover span {
    border-radius: 22px 0 0 22px;
    background-color: #FED501;
  }
  .commonBtn:hover span:before {
    border-radius: 22px 0 0 22px;
  }
  .commonBtn span {
    display: flex;
    border-radius: 22px;
    min-width: 182px;
    height: 44px;
    align-items: center;
    justify-content: center;
    color: #2C3A51;
    font-size: 14px;
    margin: 0 auto;
    padding: 0 20px;
    transition: 0.5s;
  }
  .commonBtn span:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
  }
  .backBtn {
    display: inline-block;
    position: relative;
    padding-left: 34px;
    text-decoration: none;
  }
  .backBtn:after {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    transform: scale(-1, 1);
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.5s;
    text-decoration: none;
  }
  .backBtn:before {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.5s;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .backBtn:hover:after {
    border-radius: 0 50% 50% 0;
    background-color: #FED501;
  }
  .backBtn:hover:before {
    border-radius: 50% 0 0 50%;
  }
  .backBtn:hover span {
    border-radius: 0 22px 22px 0;
    background-color: #FED501;
  }
  .backBtn:hover span:before {
    border-radius: 0 22px 22px 0;
  }
  .backBtn span {
    display: flex;
    border-radius: 22px;
    min-width: 182px;
    height: 44px;
    align-items: center;
    justify-content: center;
    color: #2C3A51;
    font-size: 14px;
    margin: 0 auto;
    padding: 0 20px;
    transition: 0.5s;
  }
  .backBtn span:before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 22px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
  }
  .articleBtn {
    display: inline-block;
    border-radius: 22px;
    min-width: 182px;
    height: 44px;
    text-align: center;
    color: #2C3A51;
    font-size: 14px;
    padding: 7px 20px 7px 34px;
    transition: 0.5s;
  }
  .articleBtn:after {
    content: "";
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-size: 14px auto;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    right: -34px;
    top: 0;
    transition: 0.5s;
  }
  .articleBtn:hover {
    border-radius: 22px 0 0 22px;
    background-color: #FED501;
  }
  .articleBtn:hover:after {
    border-radius: 0 50% 50% 0;
    background-color: #FED501;
  }
  .btnWrap {
    margin-top: 85px;
    text-align: center;
  }
  p .commonBtn + .commonBtn {
    margin-left: 40px;
  }
  .imgLine2 {
    display: flex;
    flex-wrap: wrap;
  }
  .imgLine2 li {
    width: calc(50% - 10px);
  }
  .imgLine2 li + li {
    margin-top: 0 !important;
  }
  .imgLine2 li:nth-of-type(odd) {
    margin-right: 20px;
  }
  .imgLine2 li:nth-of-type(n + 3) {
    margin-top: 20px;
  }
  .imgLine2 li img {
    max-width: 100%;
  }
  .imgLine2.small {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .imgLine2 .cap {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    color: #222;
    text-align: left;
  }
  .imgLine3 {
    display: flex;
    flex-wrap: wrap;
  }
  .imgLine3 li {
    width: calc(33.3333333333% - 14px);
    margin-right: 20px;
    padding: 0 0 50px;
  }
  .imgLine3 li + li {
    margin-top: 0 !important;
  }
  .imgLine3 li:nth-of-type(3n) {
    margin-right: 0;
  }
  .imgLine3 li:nth-of-type(n + 4) {
    margin-top: 20px;
  }
  .imgLine3 li img {
    max-width: 100%;
  }
  .imgLine3 .cap {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    color: #222;
    text-align: left;
  }
  .imgInP {
    overflow: hidden;
  }
  .imgInP .img {
    max-width: 360px;
  }
  .imgInP .img img {
    width: 100%;
    height: auto;
  }
  .imgInP .img .cap {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #2C3A51;
    text-align: left;
  }
  .imgInP .img.left {
    float: left;
    margin-right: 50px;
  }
  .imgInP .img.right {
    float: right;
    margin-left: 50px;
  }
  .twoCol {
    display: flex;
  }
  .twoCol .txt {
    flex: 1;
  }
  .twoCol .txt *:nth-child(1) {
    margin-top: 0;
  }
  .twoCol .txt + .img {
    margin-left: 40px;
  }
  .twoCol .txt + .txt {
    margin-left: 40px;
  }
  .twoCol .img {
    max-width: calc(50% - 20px);
  }
  .twoCol .img + .img {
    margin-left: 40px;
  }
  .twoCol .img + .txt {
    margin-left: 40px;
  }
  .twoCol .img img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .twoCol .cap {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #2C3A51;
    text-align: left;
  }
  .colorBox {
    padding: 40px 30px;
  }
  .colorBox .title {
    font-weight: bold;
    font-size: 18px;
    position: relative;
  }
  .colorBox * + .title {
    margin-top: 10px;
  }
  .colorBox .cont {
    margin-top: 15px;
  }
  .colorBox .cont * + p {
    margin: 10px 0 0;
  }
  .borderBox {
    padding: 40px 80px;
  }
  .borderBox .title {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
  }
  .borderBox .title:before {
    content: "";
    display: inline-block;
    background-color: #a89f9b;
    width: 42px;
    height: 1px;
    position: absolute;
    left: calc(50% - 21px);
    bottom: 0px;
  }
  .borderBox * + .title {
    margin-top: 30px;
  }
  .borderBox .cont {
    margin-top: 30px;
  }
  .borderBox .cont * + p {
    margin: 10px 0 0;
  }
  .commonTable td.wFix,
  .commonTable th.wFix {
    width: 150px;
  }
  .commonTable th {
    padding: 15px 20px;
  }
  .commonTable td {
    padding: 15px 20px;
  }
  .scrollTable {
    margin-top: 50px;
  }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
  }
  .iframeWrap {
    max-width: 780px;
    margin: 0 auto;
  }
  .iframeBox {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 40px;
  }
  .iframeBox iframe, .iframeBox video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
  * + dl.commonList {
    margin-top: 40px;
  }
  dl.commonList > div {
    display: flex;
    padding: 13px 33px;
  }
  dl.commonList > div + div {
    margin-top: 25px;
  }
  dl.commonList > div > dt {
    width: 185px;
    padding-right: 20px;
    flex: none;
  }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none;
  }
  .taC {
    text-align: center !important;
  }
  .taL {
    text-align: left !important;
  }
  .taR {
    text-align: right !important;
  }
  .Center {
    margin: 0 auto !important;
  }
  .ovh {
    overflow: hidden !important;
  }
  .mw100 {
    max-width: 100% !important;
    width: auto !important;
  }
  .w100p {
    width: 100% !important;
  }
  .pT0 {
    padding-top: 0 !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5 {
    margin-bottom: 5px !important;
  }
  .mB10 {
    margin-bottom: 10px !important;
  }
  .mB20 {
    margin-bottom: 20px !important;
  }
  .mB30 {
    margin-bottom: 30px !important;
  }
  .mB40 {
    margin-bottom: 40px !important;
  }
  .mB50 {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5 {
    margin-top: 5px !important;
  }
  .mT10 {
    margin-top: 10px !important;
  }
  .mT20 {
    margin-top: 20px !important;
  }
  .mT30 {
    margin-top: 30px !important;
  }
  .mT40 {
    margin-top: 40px !important;
  }
  .mT50 {
    margin-top: 50px !important;
  }
  .mT60 {
    margin-top: 60px !important;
  }
  .mL0 {
    margin-left: 0px !important;
  }
  .mL5 {
    margin-left: 5px !important;
  }
  .mL10 {
    margin-left: 10px !important;
  }
  .mL20 {
    margin-left: 20px !important;
  }
  .mL30 {
    margin-left: 30px !important;
  }
  .mL40 {
    margin-left: 40px !important;
  }
  .mL50 {
    margin-left: 50px !important;
  }
  .mR0 {
    margin-right: 0px !important;
  }
  .mR5 {
    margin-right: 5px !important;
  }
  .mR10 {
    margin-right: 10px !important;
  }
  .mR20 {
    margin-right: 20px !important;
  }
  .mR30 {
    margin-right: 30px !important;
  }
  .mR40 {
    margin-right: 40px !important;
  }
  .mR50 {
    margin-right: 50px !important;
  }
  .mB0_pc {
    margin-bottom: 0 !important;
  }
  .mB5_pc {
    margin-bottom: 5px !important;
  }
  .mB10_pc {
    margin-bottom: 10px !important;
  }
  .mB20_pc {
    margin-bottom: 20px !important;
  }
  .mB30_pc {
    margin-bottom: 30px !important;
  }
  .mB40_pc {
    margin-bottom: 40px !important;
  }
  .mB50_pc {
    margin-bottom: 50px !important;
  }
  .mT0_pc {
    margin-top: 0 !important;
  }
  .mT5_pc {
    margin-top: 5px !important;
  }
  .mT10_pc {
    margin-top: 10px !important;
  }
  .mT20_pc {
    margin-top: 20px !important;
  }
  .mT30_pc {
    margin-top: 30px !important;
  }
  .mT40_pc {
    margin-top: 40px !important;
  }
  .mT50_pc {
    margin-top: 50px !important;
  }
  .mT100_pc {
    margin-top: 100px !important;
  }
  .mL0_pc {
    margin-left: 0px !important;
  }
  .mL5_pc {
    margin-left: 5px !important;
  }
  .mL10_pc {
    margin-left: 10px !important;
  }
  .mL20_pc {
    margin-left: 20px !important;
  }
  .mL30_pc {
    margin-left: 30px !important;
  }
  .mL40_pc {
    margin-left: 40px !important;
  }
  .mL50_pc {
    margin-left: 50px !important;
  }
  .mR0_pc {
    margin-right: 0px !important;
  }
  .mR5_pc {
    margin-right: 5px !important;
  }
  .mR10_pc {
    margin-right: 10px !important;
  }
  .mR20_pc {
    margin-right: 20px !important;
  }
  .mR30_pc {
    margin-right: 30px !important;
  }
  .mR40_pc {
    margin-right: 40px !important;
  }
  .mR50_pc {
    margin-right: 50px !important;
  }
  .size12 {
    font-size: 12px !important;
  }
  .size14 {
    font-size: 14px !important;
  }
  .size16 {
    font-size: 16px !important;
  }
  .size18 {
    font-size: 18px !important;
  }
  .size20 {
    font-size: 20px !important;
  }
  .taC_pc {
    text-align: center !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .underline {
    text-decoration: underline !important;
  }
  .bdb {
    border-bottom: 2px solid #004aa4;
  }
  .fontL {
    font-size: 1.2em;
  }
  .fontS {
    font-size: 0.8em;
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .preBox {
    background: #eff0f4;
    border: 1px solid #A5C957;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
  }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}
@media screen and (min-width: 801px) and (max-width: 800px) {
  .thanks {
    color: #A5C957;
  }
  .singleContents .has-inline-color.has-luminous-vivid-orange-color {
    color: #A5C957 !important;
  }
  .singleContents .inner > *:first-child {
    margin-top: 0;
  }
  .singleContents p + .wp-block-gallery {
    margin-top: 10px;
  }
  h2, h3, h4 {
    line-height: 1.5;
  }
  a.icon {
    display: inline-block;
    position: relative;
    padding-right: 25px;
  }
  a.icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0;
    position: absolute;
    right: 0px;
    top: 0.2em;
  }
  a.icon[href$=pdf]:after {
    background-image: url(../images/common/icon_pdf.svg);
  }
  a.icon[href$=doc]:after, a.icon[href$=docx]:after {
    background-image: url(../images/common/icon_word.svg);
  }
  a.icon[href$=xls]:after, a.icon[href$=xlsx]:after {
    background-image: url(../images/common/icon_excel.svg);
  }
  a.icon.front {
    padding-left: 25px;
    padding-right: 0;
  }
  a.icon.front:after {
    left: 0px;
  }
  .iconBlank {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
  .imgLine2 li img {
    max-width: 100%;
  }
  .imgLine2 .cap {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    color: #222;
    text-align: left;
  }
  .imgLine3 li img {
    max-width: 100%;
  }
  .imgLine3 .cap {
    display: block;
    font-size: 18px;
    margin-top: 10px;
    color: #222;
    text-align: left;
  }
  .imgInP .img .cap {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #2C3A51;
    text-align: left;
  }
  .twoCol .cap {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    color: #2C3A51;
    text-align: left;
  }
  .scrollText {
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
  }
  .iframeBox {
    position: relative;
    padding-bottom: 56.25%;
  }
  .iframeBox iframe, .iframeBox video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
  /* common class */
  .pointerNone {
    cursor: default;
    text-decoration: none;
  }
  .taC {
    text-align: center !important;
  }
  .taL {
    text-align: left !important;
  }
  .taR {
    text-align: right !important;
  }
  .Center {
    margin: 0 auto !important;
  }
  .ovh {
    overflow: hidden !important;
  }
  .mw100 {
    max-width: 100% !important;
    width: auto !important;
  }
  .w100p {
    width: 100% !important;
  }
  .pT0 {
    padding-top: 0 !important;
  }
  .mB0 {
    margin-bottom: 0 !important;
  }
  .mB5 {
    margin-bottom: 5px !important;
  }
  .mB10 {
    margin-bottom: 10px !important;
  }
  .mB20 {
    margin-bottom: 20px !important;
  }
  .mB30 {
    margin-bottom: 30px !important;
  }
  .mB40 {
    margin-bottom: 40px !important;
  }
  .mB50 {
    margin-bottom: 50px !important;
  }
  .mT0 {
    margin-top: 0 !important;
  }
  .mT5 {
    margin-top: 5px !important;
  }
  .mT10 {
    margin-top: 10px !important;
  }
  .mT20 {
    margin-top: 20px !important;
  }
  .mT30 {
    margin-top: 30px !important;
  }
  .mT40 {
    margin-top: 40px !important;
  }
  .mT50 {
    margin-top: 50px !important;
  }
  .mT60 {
    margin-top: 60px !important;
  }
  .mL0 {
    margin-left: 0px !important;
  }
  .mL5 {
    margin-left: 5px !important;
  }
  .mL10 {
    margin-left: 10px !important;
  }
  .mL20 {
    margin-left: 20px !important;
  }
  .mL30 {
    margin-left: 30px !important;
  }
  .mL40 {
    margin-left: 40px !important;
  }
  .mL50 {
    margin-left: 50px !important;
  }
  .mR0 {
    margin-right: 0px !important;
  }
  .mR5 {
    margin-right: 5px !important;
  }
  .mR10 {
    margin-right: 10px !important;
  }
  .mR20 {
    margin-right: 20px !important;
  }
  .mR30 {
    margin-right: 30px !important;
  }
  .mR40 {
    margin-right: 40px !important;
  }
  .mR50 {
    margin-right: 50px !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .underline {
    text-decoration: underline !important;
  }
  .bdb {
    border-bottom: 2px solid #004aa4;
  }
  .fontL {
    font-size: 1.2em;
  }
  .fontS {
    font-size: 0.8em;
  }
  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .preBox {
    background: #eff0f4;
    border: 1px solid #A5C957;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
  }
  .preBox pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align-last: auto;
  }
}