//================================================
// ★サイト内共通で使うスタイルとブロックエディタで使うスタイルをmixinで管理★
// 使用例としては、
// サイト内共通で使う「h2.title」と
// ブロックエディタで使う「.singleContents h2」を
// 共通でソース管理する　という感じ
// 共通で管理しきれないものは無理に共通化しない
// ボタンやテーブルは共通化していない
// 以下mixinとクラス「.singleContents」の中身に変更を加えたら、
// 「_gb_style.scss」にも反映する
//フロント側・・・.singleContents
// エディタ側・・・.editor-styles-wrapper.block-editor-writing-flow
//================================================
@mixin pMargin {
    @if $type == sp{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    @if $type == pc{
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
@mixin h2TitleMargin {
    @if $type == sp{
        margin-top: 60px;
    }
    @if $type == pc{
        margin-top: 90px;
    }
}
@mixin h3TitleMargin {
    @if $type == sp{
        margin-top: 40px;
    }
    @if $type == pc{
        margin-top: 60px;
    }
}
@mixin h4TitleMargin {
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 50px;
    }
}
@mixin titleAfterPMargin {
    @if $type == sp{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    @if $type == pc{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
@mixin h2Title {
    @if $type == ""{
        position: relative;
        font-weight: 500;
    }
    @if $type == sp{
        font-size: 24px;
        padding-left: 30px;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            top: 10px;
        }
        &.eng{
            @include fontEn;
            font-weight: 400;
            font-size: 28px;
            &:before{
                top: 12px;
            }
        }
    }
    @if $type == pc{
        font-size: 30px;
        padding-left: 40px;
        &:before{
            content: "";
            display: inline-block;
            background: url(../images/common/midashi_mark.svg) no-repeat center / cover;
            width: 23px;
            height: 23px;
            position: absolute;
            left: 0;
            top: 13px;
        }
        &.eng{
            @include fontEn;
            font-weight: 400;
            font-size: 38px;
            &:before{
                top: 17px;
            }
        }
    }
}
@mixin h3Title {
    @if $type == ""{
        border-bottom: 2px solid $c_base;
        font-weight: 400;
        color: #222222;
    }
    @if $type == sp{
        font-size: 20px;
        padding-bottom: 20px;
    }
    @if $type == pc{
        font-size: 24px;
        padding-bottom: 20px;
    }
}
@mixin h4Title {
    @if $type == ""{
        color: $c_base;
    }
    @if $type == sp{
        font-size: 16px;
    }
    @if $type == pc{
        font-size: 20px;
    }
}
@mixin commonList {
    @if $type == ""{
        padding-left: 0;
    }
    @if $type == sp{
        li{
            padding-left: 20px;
            position: relative;

            &:not(:first-of-type){
                margin-top: 10px;
            }

            &:before{
                content: "";
                display: block;
                width: 7px;
                height: 7px;
                background: $c_base;
                position: absolute;
                margin: auto;
                top: 10px;
                left: 0px;
                border-radius: 50%;
            }
        }
    }
    @if $type == pc{
        li{
            padding-left: 30px;
            position: relative;

            &:not(:first-of-type){
                margin-top: 15px;
            }

            &:before{
                content: "";
                display: block;
                width: 7px;
                height: 7px;
                background: $c_base;
                position: absolute;
                margin: auto;
                top: 8px;
                left: 9px;
                border-radius: 50%;
            }
        }
    }
}
@mixin olList {
    @if $type == ""{
        counter-reset: item;
        list-style-type: none;
        padding-left: 0;
    }
    li{
        @if $type == ""{
            position: relative;
        }
        @if $type == sp{
            padding-left: 15px;
            counter-increment: mycounter;
            &:before{
                content: counter(mycounter)'.';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                color: #898989;
                font-weight: bold;
            }
            &:not(:first-of-type){
                margin-top: 10px;
            }
        }
        @if $type == pc{
            padding-left: 20px;
            counter-increment: mycounter;
            &:before{
                content: counter(mycounter)'.';
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                color: #898989;
                font-weight: bold;
            }
            &:not(:first-of-type){
                margin-top: 15px;
            }
        }
    }
}

@mixin colorBox{
    @if $type == ""{
        background-color: #fff;
        .cont p{
            margin: 0;
        }
    }
    @if $type == sp{
        padding: 20px 20px;
        .title{
            font-weight: bold;
            font-size: 15px;
            position: relative;
        }
        * + .title{
            margin-top: 10px;
        }
        .cont{
            margin-top: 10px;
        }
        .cont * + p{
            margin: 10px 0 0;
        }
    }
    @if $type == pc{
        padding: 40px 30px;
        .title{
            font-weight: bold;
            font-size: 18px;
            position: relative;
        }
        * + .title{
            margin-top: 10px;
        }
        .cont{
            margin-top: 15px;
        }
        .cont * + p{
            margin: 10px 0 0;
        }
    } 
}

@mixin borderBox{
    @if $type == ""{
        border: 1px solid #555;
        .cont p{
            margin: 0;
        }
    }
    @if $type == sp{
        padding: 20px 20px;
        .title{
            font-weight: bold;
            font-size: 16px;
            position: relative;
            padding-bottom: 10px;
            text-align: center;
            &:before{
                content: "";
                display: inline-block;
                background-color: #a89f9b;
                width: 42px;
                height: 1px;
                position: absolute;
                left: calc(50% - 21px);
                bottom: 0px;
            }
        }
        * + .title{
            margin-top: 30px;
        }
        .cont{
            margin-top: 20px;
        }
        .cont * + p{
            margin: 10px 0 0;
        }
    }
    @if $type == pc{
        padding: 40px 80px;
        .title{
            font-weight: bold;
            font-size: 20px;
            position: relative;
            padding-bottom: 10px;
            text-align: center;
            &:before{
                content: "";
                display: inline-block;
                background-color: #a89f9b;
                width: 42px;
                height: 1px;
                position: absolute;
                left: calc(50% - 21px);
                bottom: 0px;
            }
        }
        * + .title{
            margin-top: 30px;
        }
        .cont{
            margin-top: 30px;
        }
        .cont * + p{
            margin: 10px 0 0;
        }
    } 
}


//================================================
// ★ブロックエディタで使うCSS★
// フロント側用のクラス「.singleContents」
//================================================
// ▼_gb_style.scssにコピペ ここから▼
.singleContents{
    .has-inline-color.has-luminous-vivid-orange-color{
        color: $c_base!important;
    }
    .inner{
        & > *:first-child{
            margin-top: 0;
        }
    }
    //================================================
    // 余白
    //================================================
    p{
        @if $type == sp {
        }
        @if $type == pc {
        }
    }
    // * + p {
    //     @include pMargin;
    // }
    p {
        @include pMargin;
    }
    h3 + p,h4 + p{
        @include titleAfterPMargin;
    }
    * + h2{
        @include h2TitleMargin;
    }
    * + h3{
        @include h3TitleMargin;
    }
    * + h4{
        @include h4TitleMargin;
    }

    //================================================
    // タイトル
    //================================================
    h2{
        @include h2Title;
    }
    h3{
        @include h3Title;
    }
    h4{
        @include h4Title;
    }

    //================================================
    // リスト
    //================================================
    ul{
        @include commonList;
    }
    ol{
        @include olList;
    }
    * + ul, * + ol{
        @if $type == sp{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        @if $type == pc{
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    //================================================
    // 既存ブロック（オーバーライド）
    //================================================
    p + .wp-block-gallery{
        margin-top: 10px;
    }
    // ボタン
    .wp-block-button{
        @if $type == sp{
            margin-bottom: 30px;
        }
        @if $type == pc{
            margin-bottom: 50px;
        }
        a, .wp-block-button__link{
            @if $type == ""{
                background-color: #fff;
                position: relative;
            }
            @if $type==sp {
                display: inline-block;
                border-radius: 22px;
                min-width: 172px;
                height: 40px;
                text-align: center;
                color: #2C3A51;
                font-size: 14px;
                padding: 5px 20px 5px 30px;
                margin: 0 auto;
                &:after{
                    content: "";
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    background: url(../images/common/arrow.svg) no-repeat center / cover;
                    background-size: 14px auto;
                    background-color: #fff;
                    border-radius: 50%;
                    position: absolute;
                    right: -30px;
                    top: 0;
                }
            }
            @if $type==pc {
                display: inline-block;
                border-radius: 22px;
                min-width: 182px;
                height: 44px;
                text-align: center;
                color: #2C3A51;
                font-size: 14px;
                padding: 7px 20px 7px 34px;
                transition: .5s;
                &:after{
                    content: "";
                    display: inline-block;
                    width: 44px;
                    height: 44px;
                    background: url(../images/common/arrow.svg) no-repeat center / cover;
                    background-size: 14px auto;
                    background-color: #fff;
                    border-radius: 50%;
                    position: absolute;
                    right: -34px;
                    top: 0;
                    transition: .5s;
                }
                &:hover{
                    border-radius: 22px 0 0 22px;
                    background-color: $c_yellow;
                    &:after{
                        border-radius: 0 50% 50% 0;
                        background-color: $c_yellow;
                    }
                }
            }
        }
        .wp-block-button__link{
        }
        &.is-style-outline{
            .wp-block-button__link{
            }
        }
    }
    .wp-block-buttons{
        @if $type == sp{
            margin-top: 30px;
            margin-bottom: 30px;
        }
        @if $type == pc{
            margin-top: 50px;
            margin-bottom: 50px;
        }
        .wp-block-button{
            @if $type == sp{
            }
            @if $type == pc{
            }
        }
    }
    // テーブル
    .wp-block-table{
        @if $type == "" {
            width: 100%;
            border-collapse: collapse;
            border-spacing: inherit;
            text-align: left;
            border: 1px solid $c_border;
        }
        @if $type == sp{
            margin-bottom: 30px;
            margin-top: 30px;
        }
        @if $type == pc{
            margin-bottom: 50px;
            margin-top: 50px;
        }
        thead{
            @if $type == "" {
                border-bottom: none;
            }
        }
        tr{
            th{
                @if $type == ""{
                    font-weight: bold;
                    border: none;
                    border-bottom: 1px solid $c_border;
                    border-top: 1px solid $c_border;
                    border-left: 1px solid $c_border;
                    &:last-child{
                        margin-bottom: 0;
                        border-right: none;
                    }
                    &.sub{
                        background-color: $c_base;
                        color: #fff;
                    }
                }

                @if $type == sp{
                    padding: 10px 10px;
                }
                @if $type == pc{
                    padding: 15px 20px;
                }
            }
            td{
                @if $type == ""{
                    border: none;
                    border-bottom: 1px solid $c_border;
                    border-top: 1px solid $c_border;
                    border-left: 1px solid $c_border;
                    word-break: break-all;
                    background-color: #fff;
                    &:last-child{
                        margin-bottom: 0;
                        border-right: none;
                    }
                }

                @if $type == sp{
                    padding: 10px 10px;
                }
                @if $type == pc{
                    padding: 15px 20px;
                }
            }
        }
        &.is-style-stripes{
            td:nth-child(odd){
                @if $type == ""{
                    background-color: #f3f3f3;
                }
            }
        }	
    }
    .wp-block-image{
        @if $type == "" {
        }
        @if $type == sp{
            margin-bottom: 30px;
            margin-top: 30px;
        }
        @if $type == pc{
            margin-bottom: 70px;
            margin-top: 50px;
        }
    }
    .wp-block-columns{
        .wp-block-image{
            @if $type == "" {
                margin-bottom: 0px;
                margin-top: 0;
            }
        }
        p{
            @if $type == "" {
                margin: 0;
            }
        }
    }
    .wp-block-media-text__media{
        img{
        }
    }
    // メディアとテキスト
    .wp-block-media-text .wp-block-media-text__content{
        @if $type == sp {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
        }
        p{
            @if $type == "" {
                margin-top: 0;
            }
        }
    }
    .wp-block-media-text{
        @if $type == sp {
            margin-bottom: 30px;
            margin-top: 30px;
        }
        @if $type == pc {
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }
    // グループ
    .wp-block-group,.wp-block-columns {
        @if $type == sp {
            margin-bottom: 30px;
            margin-top: 30px;
        }
        @if $type == pc {
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }
    .wp-block-gallery.has-nested-images figure.wp-block-image figcaption{
        @if $type == "" {
            background: none;
            color: $c_text;
            position: static;
            font-size: 12px;
        }
    }
    .wp-block-gallery.has-nested-images figure.wp-block-image{
        @if $type == "" {
            display: initial;
            img{
                height: auto!important;
            }
        }
    }
    .wp-block-gallery + .wp-block-gallery {
        @if $type == sp {
            margin-bottom: 30px;
            margin-top: 30px;
        }
        @if $type == pc {
            margin-bottom: 70px;
            margin-top: 50px;
        }
    }

    //================================================
    // カスタムブロック
    //================================================
    .colorBox{
        @include colorBox;
    }
    .borderBox{
        @include borderBox;
    }

}
// ▲_gb_style.scssにコピペ ここまで▲
// ブロックエディタ用の記述ここまで



//================================================
// ★サイト内共通で使うCSS★
//================================================

//================================================
// 余白
//================================================
* + p {
    @if $type == sp{
        margin-top: 20px;
        &.wide{
            margin-top: 30px;
        }
    }
    @if $type == pc{
        margin-top: 30px;
        &.wide{
            margin-top: 60px;
        }
    }
}

h2,h3,h4{
    line-height: 1.5;
}

h3 + p,h4 + p{
    @include titleAfterPMargin;
}
h3 + .commonList{
    @include titleAfterPMargin;
}
* + h2.title, * + .h2 {
    @include h2TitleMargin;
}

* + h3.title {
    @include h3TitleMargin;
}
* + h4.title {
    @include h4TitleMargin;
}


//================================================
// タイトル
//================================================
h2.title{
    @include h2Title;
}

h3.title{
    @include h3Title;
}
h4.title{
    @include h4Title;
}

.h2{
    @include h2Title;
}

//================================================
// リスト
//================================================
ul.commonList{
    @include commonList;
}

ol{
    @include olList;
}

//アキ調整
section + section{
    @if $type == sp{
        margin-top: 70px;
    }
    @if $type == pc{
        margin-top: 90px;
    }
}

* + .commonList, * + .linkList , * + .colorBox,  * + .borderBox, * + .commonTable{
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}

* + .borderBox{
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}

* + ol {
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}

* + .imgLine2, * + .imgLine3, * + .twoCol{
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}


a.icon{
    display: inline-block;
    position: relative;
    padding-right: 25px;

    &:after{
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 0;
        position: absolute;
        right: 0px;
        top: .2em;
    }

    &[href $= pdf]{
        &:after{
            background-image: url(../images/common/icon_pdf.svg);
        }
    }

    &[href $= doc], &[href $= docx]{
        &:after{
            background-image: url(../images/common/icon_word.svg);
        }
    }

    &[href $= xls], &[href $= xlsx]{
        &:after{
            background-image: url(../images/common/icon_excel.svg);
        }
    }

    &.front{
        padding-left: 25px;
        padding-right: 0;
        &:after{
            left: 0px;
        }
    }
}

.iconBlank{
    display: inline-block;
    width: 13px;
    height: 13px;
    background:url("../images/common/icon_blank.svg") no-repeat 0 0;
    background-size: cover;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -2px;
}

.commonBtn{
    @if $type==sp {
        display: inline-block;
        position: relative;
        padding-right: 30px;
        text-decoration: none;
        &:after{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
    }
    @if $type==pc {
        display: inline-block;
        position: relative;
        padding-right: 34px;
        text-decoration: none;
        &:after{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            transition: .5s;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 0;
            transition: .5s;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
        &:hover{
            &:after{
                border-radius: 0 50% 50% 0;
                background-color: $c_yellow;
            }
            &:before{
                border-radius: 0 50% 50% 0;
            }
            span{
                border-radius: 22px 0 0 22px;
                background-color: $c_yellow;
                &:before{
                    border-radius: 22px 0 0 22px;
                }
            }
        }
    }
    span{
        @if $type=="" {
            background-color: #fff;
            position: relative;
        }
        @if $type==sp {
            display: flex;
            border-radius: 22px;
            min-width: 172px;
            height: 40px;
            align-items: center;
            justify-content: center;
            color: #2C3A51;
            font-size: 14px;
            padding: 0 20px;
            margin: 0 auto;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            }
        }
        @if $type==pc {
            display: flex;
            border-radius: 22px;
            min-width: 182px;
            height: 44px;
            align-items: center;
            justify-content: center;
            color: #2C3A51;
            font-size: 14px;
            margin: 0 auto;
            padding: 0 20px;
            transition: .5s;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                transition: .5s;
            }
        }
    }
}

.backBtn{
    @if $type==sp {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        text-decoration: none;
        &:after{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            transform: scale(-1, 1);
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
    }
    @if $type==pc {
        display: inline-block;
        position: relative;
        padding-left: 34px;
        text-decoration: none;
        &:after{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            transform: scale(-1, 1);
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            transition: .5s;
            text-decoration: none;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            transition: .5s;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }
        &:hover{
            &:after{
                border-radius: 0 50% 50% 0;
                background-color: $c_yellow;
            }
            &:before{
                border-radius: 50% 0 0 50%;
            }
            span{
                border-radius: 0 22px 22px 0;
                background-color: $c_yellow;
                &:before{
                    border-radius: 0 22px 22px 0;
                }
            }
        }
    }
    span{
        @if $type=="" {
            background-color: #fff;
            position: relative;
        }
        @if $type==sp {
            display: flex;
            border-radius: 22px;
            min-width: 172px;
            height: 40px;
            align-items: center;
            justify-content: center;
            color: #2C3A51;
            font-size: 14px;
            padding: 0 20px;
            margin: 0 auto;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            }
        }
        @if $type==pc {
            display: flex;
            border-radius: 22px;
            min-width: 182px;
            height: 44px;
            align-items: center;
            justify-content: center;
            color: #2C3A51;
            font-size: 14px;
            margin: 0 auto;
            padding: 0 20px;
            transition: .5s;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
                transition: .5s;
            }
        }
    }
}

.articleBtn{
    @if $type=="" {
        background-color: #fff;
        position: relative;
        text-decoration: none;
    }
    @if $type==sp {
        display: inline-block;
        border-radius: 22px;
        min-width: 172px;
        height: 40px;
        text-align: center;
        color: #2C3A51;
        font-size: 14px;
        padding: 5px 20px 5px 30px;
        margin: 0 auto;
        &:after{
            content: "";
            display: inline-block;
            width: 40px;
            height: 40px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: -30px;
            top: 0;
        }
    }
    @if $type==pc {
        display: inline-block;
        border-radius: 22px;
        min-width: 182px;
        height: 44px;
        text-align: center;
        color: #2C3A51;
        font-size: 14px;
        padding: 7px 20px 7px 34px;
        transition: .5s;
        &:after{
            content: "";
            display: inline-block;
            width: 44px;
            height: 44px;
            background: url(../images/common/arrow.svg) no-repeat center / cover;
            background-size: 14px auto;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            right: -34px;
            top: 0;
            transition: .5s;
        }
        &:hover{
            border-radius: 22px 0 0 22px;
            background-color: $c_yellow;
            &:after{
                border-radius: 0 50% 50% 0;
                background-color: $c_yellow;
            }
        }
    }
}

.btnWrap{
    @if $type == sp{
        margin-top: 90px;
        text-align: center;
    }
    @if $type == pc{
        margin-top: 85px;
        text-align: center;
    }
}


p .commonBtn + .commonBtn{
    @if $type == sp{
        margin-top: 20px;
    }
    @if $type == pc{
        margin-left: 40px;
    }
}


.imgLine2{
    @if $type == sp{
        &.spflex{
            display: flex;
            li{
                margin: 0;
                width: calc(50% - 8px);
                &:nth-of-type(odd){
                    margin-right: 16px;
                }
            }
        }
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }

    li{
        @if $type == ""{
            text-align: center;
            padding: 0!important;
            &:before{
                content: none!important;
            }
        }
        @if $type == sp{
            margin-top: 10px!important;
        }
        @if $type == pc{
            width: calc(50% - 10px);
            + li{
                margin-top: 0!important;
            }
            &:nth-of-type(odd){
                margin-right: 20px;
            }
            &:nth-of-type(n + 3){
                margin-top: 20px;
            }
        }

        img{
            //width: 100%;
            max-width: 100%;
        }
    }

    &.small{
        @if $type == pc{
            width: 80%;
            margin-left:auto;
            margin-right:auto;
        }
    }
    .cap{
        display: block;
        font-size: 18px;
        margin-top: 10px;
        color: #222;
        text-align: left;

			@if $type == sp{
				font-size: 14px;
			}
	}
}

.imgLine3{

    @if $type == sp{
    }
    @if $type == pc{
        display: flex;
        flex-wrap: wrap;
    }

    li{
        @if $type == ""{
            text-align: center;
            //padding: 0!important;
            &:before{
                content: none!important;
            }
        }
			
        @if $type == sp{
            margin-top: 10px!important;
					padding: 0 0 30px;
        }
			
        @if $type == pc{
            width: calc((100% / 3 ) - 14px);
            margin-right: 20px;
					padding: 0 0 50px;
					
            + li{
                margin-top: 0!important;
            }
					
            &:nth-of-type(3n){
                margin-right: 0;
            }
					
            &:nth-of-type(n + 4){
                margin-top: 20px;
            }
        }

        img{
            max-width: 100%;
        }
    }
    .cap{
        display: block;
        font-size: 18px;
        margin-top: 10px;
        color: #222;
        text-align: left;

			@if $type == sp{
				font-size: 14px;
			}
	}
}



.imgInP{
    @if $type == sp{
    }
    @if $type == pc{
        overflow: hidden;
    }

    .img{
        @if $type == sp{
            display: block;
            margin: 0 auto;
            padding-bottom: 20px;
        }
        @if $type == pc{
            max-width: 360px;

            img{
                width: 100%;
                height: auto;
            }
        }

        .cap{
            display: block;
            font-size: 12px;
            margin-top: 10px;
            color: $c_text;
            text-align: left;

					@if $type == sp{
						font-size: 12px;
					}
			}
    }

    .img.left{
        @if $type == sp{
        }
        @if $type == pc {
            float: left;
            margin-right: 50px;
        }
    }

    .img.right{
        @if $type == sp{
        }
        @if $type == pc{
            float: right;
            margin-left: 50px;
        }
    }

    &.sp_img_btm{
        @if $type == sp{
            display: flex;
            flex-wrap: wrap;

            .img{
                display: block;
                order: 2;
                margin: 20px auto 0;
                padding-bottom: 0;
            }

            .text{
                flex-basis: 100%;
                order: 1;
            }
        }
    }
}

.twoCol{

    @if $type == sp{

        .txt{

            *:nth-child(1){
                margin-top: 0;
            }

            & + .img{
                margin-top: 30px;
            }

            & + .txt{
                margin-top: 30px;
            }
        }

        .img{
            width: 100%;
            text-align: center;

            & + .img{
                margin-top: 30px;
            }

            & + .txt{
                margin-top: 30px;
            }

            img{
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }
    @if $type == pc{
        display: flex;

        .txt{
            flex: 1;

            *:nth-child(1){
                margin-top: 0;
            }

            & + .img{
                margin-left: 40px;
            }

            & + .txt{
                margin-left: 40px;
            }
        }

        .img{
            max-width: calc(50% - 20px);

            & + .img{
                margin-left: 40px;
            }

            & + .txt{
                margin-left: 40px;
            }

            img{
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }

    .cap{
        display: block;
        font-size: 12px;
        margin-top: 10px;
        color: $c_text;
        text-align: left;
			
			@if $type == sp{
				font-size: 12px;
			}
    }
}

.colorBox{
    @include colorBox;
}
.borderBox{
    @include borderBox;
}

//テーブル
.commonTable{
    @if $type == ""{
        width: 100%;
        border-collapse: collapse;
        border-spacing: inherit;
        text-align: left;
        border: 1px solid $c_border;
        .notes{
            font-size: 13px;
        }
        &.is-style-stripes td{
            &:nth-of-type(odd){
                background-color: #f3f3f3;
            }
        }
    }

    td.wFix,
    th.wFix{
        @if $type == sp{
            width: 120px;
        }
        @if $type == pc{
            width: 150px;
        }
    }

    th{
        @if $type == ""{
            font-weight: bold;
            border: none;
            border-bottom: 1px solid $c_border;
            border-top: 1px solid $c_border;
            border-left: 1px solid $c_border;
            &:last-child{
                margin-bottom: 0;
                border-right: none;
            }
            &.sub{
                background-color: $c_base;
                color: #fff;
            }
        }

        @if $type == sp{
            padding: 10px 10px;
        }
        @if $type == pc{
            padding: 15px 20px;
        }
    }

    td{
        @if $type == ""{
            border: none;
            border-bottom: 1px solid $c_border;
            border-top: 1px solid $c_border;
            border-left: 1px solid $c_border;
            word-break: break-all;
            background-color: #fff;
            &:last-child{
                margin-bottom: 0;
                border-right: none;
            }
        }

        @if $type == sp{
            padding: 10px 10px;
        }
        @if $type == pc{
            padding: 15px 20px;
        }
    }
}

.scrollTable{
    @if $type == ""{
        overflow: auto;
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 50px;
    }
    table{
        @if $type == sp{
            white-space: nowrap;
        }
    }
}

.scrollText{
    font-size: 12px;
    display: block;
    text-align: right;
    margin: 30px 0 -20px;
}


.iframeWrap{
    @if $type == pc{
        max-width: 780px;
        margin: 0 auto;
    }
}
.iframeBox{
    position: relative;
    padding-bottom: 56.25%;

    iframe,video{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 0;
    }
    @if $type == sp{
        margin-top: 30px;
    }
    @if $type == pc{
        margin-top: 40px;
    }
}

.ofh{
    @if $type == ""{
        overflow: hidden;
    }
}

*+dl.commonList {
    @if $type=="" {}

    @if $type==sp {
        margin-top: 30px;
    }

    @if $type==pc {
        margin-top: 40px;
    }
}

dl.commonList {
    @if $type=="" {
    }

    @if $type==sp {}

    @if $type==pc {}

    > div{
        @if $type=="" {
            background-color: #fff;
            border-radius: 5px;
        }
        @if $type==sp {
            padding: 12px;
            + div{
                margin-top: 15px;
            }
        }
        @if $type==pc {
            display: flex;
            padding: 13px 33px;
            + div{
                margin-top: 25px;
            }
        }
        > dt{
            @if $type=="" {
            }
            @if $type==sp {
            }
            @if $type==pc {
                width: 185px;
                padding-right: 20px;
                flex: none;
            }
        }
        > dd{
            @if $type=="" {
                font-weight: 400;
            }
            @if $type==sp {
                margin-top: 5px;
            }
            @if $type==pc {
            }
        }
    }
}

/* common class */

.pointerNone{
    cursor: default;
    text-decoration: none;
}

.taC{text-align: center!important;}
.taL{text-align: left!important;}
.taR{text-align: right!important;}
.Center{margin: 0 auto!important;}
.ovh{overflow: hidden!important;}
.mw100{max-width: 100%!important;
    width: auto!important;}
.w100p{width: 100%!important;}

.pT0{padding-top: 0!important;}
.mB0{margin-bottom: 0!important;}
.mB5{margin-bottom: 5px!important;}
.mB10{margin-bottom: 10px!important;}
.mB20{margin-bottom: 20px!important;}
.mB30{margin-bottom: 30px!important;}
.mB40{margin-bottom: 40px!important;}
.mB50{margin-bottom: 50px!important;}
.mT0{margin-top: 0!important;}
.mT5{margin-top: 5px!important;}
.mT10{margin-top: 10px!important;}
.mT20{margin-top: 20px!important;}
.mT30{margin-top: 30px!important;}
.mT40{margin-top: 40px!important;}
.mT50{margin-top: 50px!important;}
.mT60{margin-top: 60px!important;}
.mL0{margin-left: 0px!important;}
.mL5{margin-left: 5px!important;}
.mL10{margin-left: 10px!important;}
.mL20{margin-left: 20px!important;}
.mL30{margin-left: 30px!important;}
.mL40{margin-left: 40px!important;}
.mL50{margin-left: 50px!important;}
.mR0{margin-right: 0px!important;}
.mR5{margin-right: 5px!important;}
.mR10{margin-right: 10px!important;}
.mR20{margin-right: 20px!important;}
.mR30{margin-right: 30px!important;}
.mR40{margin-right: 40px!important;}
.mR50{margin-right: 50px!important;}

@if $type == sp{
    .mB0{margin-bottom: 0!important;}
    .mB5_sp{margin-bottom: 5px!important;}
    .mB10_sp{margin-bottom: 10px!important;}
    .mB20_sp{margin-bottom: 20px!important;}
    .mB30_sp{margin-bottom: 30px!important;}
    .mB40_sp{margin-bottom: 40px!important;}
    .mB50_sp{margin-bottom: 50px!important;}
    .mT0{margin-top: 0!important;}
    .mT5_sp{margin-top: 5px!important;}
    .mT10_sp{margin-top: 10px!important;}
    .mT20_sp{margin-top: 20px!important;}
    .mT30_sp{margin-top: 30px!important;}
    .mT40_sp{margin-top: 40px!important;}
    .mT50_sp{margin-top: 50px!important;}
    .mL0_sp{margin-left: 0px!important;}
    .mL5_sp{margin-left: 5px!important;}
    .mL10_sp{margin-left: 10px!important;}
    .mL20_sp{margin-left: 20px!important;}
    .mL30_sp{margin-left: 30px!important;}
    .mL40_sp{margin-left: 40px!important;}
    .mL50_sp{margin-left: 50px!important;}
    .mR0_sp{margin-right: 0px!important;}
    .mR5_sp{margin-right: 5px!important;}
    .mR10_sp{margin-right: 10px!important;}
    .mR20_sp{margin-right: 20px!important;}
    .mR30_sp{margin-right: 30px!important;}
    .mR40_sp{margin-right: 40px!important;}
    .mR50_sp{margin-right: 50px!important;}
    .size12_sp{font-size: 12px !important;}
    .size14_sp{font-size: 14px !important;}
    .size16_sp{font-size: 16px!important;}
    .size18_sp{font-size: 18px!important;}
    .size20_sp{font-size: 20px!important;}
    .w50p_sp{width: 50%!important;}
    .taL_sp{text-align: left!important;}
    .taC_sp{text-align: center!important;}
}
@if $type == pc{
    .mB0_pc{margin-bottom: 0!important;}
    .mB5_pc{margin-bottom: 5px!important;}
    .mB10_pc{margin-bottom: 10px!important;}
    .mB20_pc{margin-bottom: 20px!important;}
    .mB30_pc{margin-bottom: 30px!important;}
    .mB40_pc{margin-bottom: 40px!important;}
    .mB50_pc{margin-bottom: 50px!important;}
    .mT0_pc{margin-top: 0!important;}
    .mT5_pc{margin-top: 5px!important;}
    .mT10_pc{margin-top: 10px!important;}
    .mT20_pc{margin-top: 20px!important;}
    .mT30_pc{margin-top: 30px!important;}
    .mT40_pc{margin-top: 40px!important;}
    .mT50_pc{margin-top: 50px!important;}
    .mT100_pc{margin-top: 100px!important;}
    .mL0_pc{margin-left: 0px!important;}
    .mL5_pc{margin-left: 5px!important;}
    .mL10_pc{margin-left: 10px!important;}
    .mL20_pc{margin-left: 20px!important;}
    .mL30_pc{margin-left: 30px!important;}
    .mL40_pc{margin-left: 40px!important;}
    .mL50_pc{margin-left: 50px!important;}
    .mR0_pc{margin-right: 0px!important;}
    .mR5_pc{margin-right: 5px!important;}
    .mR10_pc{margin-right: 10px!important;}
    .mR20_pc{margin-right: 20px!important;}
    .mR30_pc{margin-right: 30px!important;}
    .mR40_pc{margin-right: 40px!important;}
    .mR50_pc{margin-right: 50px!important;}
    .size12{font-size: 12px !important;}
    .size14{font-size: 14px !important;}
    .size16{font-size: 16px!important;}
    .size18{font-size: 18px!important;}
    .size20{font-size: 20px!important;}
    .taC_pc{text-align: center!important;}
}
.bold{font-weight: bold !important;}
.underline{text-decoration: underline!important;}
.bdb{border-bottom: 2px solid #004aa4;}
.fontL{font-size: 1.2em;}
.fontS{font-size: .8em;}

.floatL{float: left;}
.floatR{float: right;}

.preBox{
    background: #eff0f4;
    border: 1px solid $c_base;
    padding: 15px;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
    margin-top: 30px;
    font-family: Arial, sans-serif;
    line-height: 1.8;

    pre{
        overflow: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align-last: auto;
    }
}

